import React, { useState, useEffect } from 'react';
import { countryMappings, lineGraphVariables } from '../../utils/constants';
import { formatList, ShockVarCodes } from '../../utils/utilities';
import { GetUserProfileInfo, UserHelpDataUpdate } from '../../pocketbase/constants';
import { Grid, Box, FormHelperText, IconButton, InputLabel, Tooltip as MaterialTooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Info } from '@material-ui/icons';
import Highcharts from 'highcharts';
import {
	HighchartsProvider,
	HighchartsChart,
	Loading,
	Title,
	Subtitle,
	Chart,
	Tooltip,
	XAxis,
	PlotLine,
	YAxis,
	LineSeries,
	AreaSeries,
} from 'react-jsx-highcharts';
import ErrorBoundary from '../ErrorBoundary';
// import Modal from '../modals/PortCenModal';
import Modal from '../modals/MacroCenModal';
import GHGMapCenModal from '../modals/GHGCenModal';
import EnergyMapCenModal from '../modals/EnergyCenModal';
import PortMapCenModal from '../modals/PortCenModal';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import scenarios, { scenarioTypes } from '../../utils/sfScenarios';
import BusinessScenarioSizes, { BusinessScenarioTypes } from '../../utils/bfScenarios';
import MapPageVarAbbrModal from '../modals/MapPageVarAbbrModal';
import MapPageScenarioKeyModal from '../modals/MapPageScenarioKeyModal';

type CSVData = Record<string, number> & { Date: number };
type LineChartData = {
	name: string;
	data: number[];
	color?: string;
	dashStyle?: any;
	visible?: boolean;
	type?: string;
	percentVariable?: boolean;
};

interface Props {
	colourTitle: string;
	dataPrefix: string;
	lineData: LineChartData[];
	selectedCountriesForGraph: CSVData[];
	variable: string[];
	minYear: number;
	colour: string;
	directionRef: any;
	scenarioType: string;
	format: string;
	scenarioSize: string[];
	loading: boolean;
	title: string;
	animationClick: boolean;
	lineGraphVariablesSelected: any;
	type: any;
	maxColour: string;
}
const LineGraphMapPage: React.FC<Props> = ({
	colourTitle,
	dataPrefix,
	lineData,
	selectedCountriesForGraph,
	variable,
	minYear,
	colour,
	directionRef,
	scenarioType,
	format,
	scenarioSize,
	loading,
	title,
	animationClick,
	lineGraphVariablesSelected,
	type,
	maxColour
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [openGHGMap, setOpenGHGMap] = useState<boolean>(false);
	const [openEnergyMap, setOpenEnergyMap] = useState<boolean>(false);
	const [openPortMap, setOpenPortMap] = useState<boolean>(false);
	const [loadingChatBot, setLoadingChatBot] = useState<boolean>(false);

	const openModal = (): void => {
		if (dataPrefix == 'MacroMap') {
			setOpen(true);
			return;
		}
		if (dataPrefix == 'GHGMap') {
			setOpenGHGMap(true);
			return;
		}
		if (dataPrefix == 'EnergyMap') {
			setOpenEnergyMap(true);
			return;
		}
		setOpenPortMap(true);
	};

	const handleHelpIconClick = () => {
		setLoadingChatBot(true);
		let profileID: any = localStorage.getItem('profileID');
		let countriesArr: any = [];
		let impactDataArr: any = [];
		let tempLineData: any = lineData;

		selectedCountriesForGraph.map((country: any) => {
			let countryMapping = countryMappings.find((obj) => obj['cnty-suffix'] === country?.suffix);
			const countryName = countryMapping?.['cntyname-mgem'] ?? '';
			countriesArr?.push({
				name: countryName,
				suffix: `_${country?.suffix}`,
			});
		});

		if (lineGraphVariablesSelected?.length) {
			lineGraphVariablesSelected.map((lineVar: any) => {
				lineGraphVariables?.map((varType: any) => {
					if (varType?.value == lineVar) {
						countriesArr?.push({
							name: varType?.label,
							suffix: `_${lineVar}`,
						});
					}
				});
			});
		} else {
			if (variable?.length == 1) {
				countriesArr?.push({
					name: 'World',
					suffix: `_WD`,
				});
			}
		}

		tempLineData?.map((lineValueObj: any, index: any) => {
			lineValueObj?.data?.map((lineValueData: any, childIndex: any) => {
				impactDataArr?.push({
					year: minYear + childIndex,
					value: Number(lineValueData?.value) || lineValueData?.value == 0 ? lineValueData?.value : lineValueData,
				});
			});
			lineValueObj.data = impactDataArr;
			impactDataArr = [];
		});

		let helpObjDetails = {
			shock_type: scenarioType,
			shock_size: scenarioSize,
			shock_variable: ShockVarCodes[scenarioType],
			// output_variable: variable?.substring(variable.lastIndexOf('_') + 1, variable?.length),
			output_variables: variable,
			countries: countriesArr,
			format: format,
			impact_data: tempLineData,
		};

		GetUserProfileInfo(profileID)
			.then((res) => {
				UserHelpDataUpdate({
					userID: res?.id,
					helpAIDetails: helpObjDetails,
					localContextFileDumpName: `${res?.id}_${dataPrefix}.json`,
				})
					.then((resSecondary) => {
						const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
							JSON.stringify({ userID: res?.id, helpAIDetails: helpObjDetails })
						)}`;
						const link = document.createElement('a');
						link.href = jsonString;
						link.download = `${res?.id}_${dataPrefix}.json`;
						link.click();
						setLoadingChatBot(false);
					})
					.catch((err) => {
						setLoadingChatBot(false);
					});
			})
			.catch((err) => {
				setLoadingChatBot(false);
			});
	};

	const [openSourcesVarAbbr, setopenSourcesVarAbbr] = useState<boolean>(false);
	const [openScenarioAbbrModal, setOpenScenarioAbbrModal] = useState<boolean>(false);

	const openSourcesMacroMapModalAbbrVar = (): void => {
		setopenSourcesVarAbbr(true);
	};

	const openScenarioAbbr = (): void => {
		setOpenScenarioAbbrModal(true);
	}

	const handleClose = (): void => {
		if (dataPrefix == 'MacroMap') {
			setOpen(false);

			return;
		}
		if (dataPrefix == 'GHGMap') {
			setOpenGHGMap(false);

			return;
		}
		if (dataPrefix == 'EnergyMap') {
			setOpenEnergyMap(false);

			return;
		}
		setOpenPortMap(false);
	};
	const filterDataByAxis = () => {
		const leftAxisData: LineChartData[] = [];
		const rightAxisData: LineChartData[] = [];

		// Check if we have any percent variables
		const hasPercentVars = lineData.every(series => series.percentVariable);
		const hasNonPercentVars = lineData.every(series => !series.percentVariable);

		// For central scenario, always show all data on left axis
		if (scenarioType === 'cen') {
			return { leftAxisData: lineData, rightAxisData: [] };
		}
		lineData.forEach(series => {
			if (series.percentVariable) {
				// Create new object to avoid mutating original data
				const modifiedSeries = {
					...series,
					// Add (RHS) to name only if we have both types and format is pct
					name: !hasPercentVars && !hasNonPercentVars && format === 'pct'
						? `${series.name} (RHS)`
						: series.name
				};
				rightAxisData.push(modifiedSeries);
			} else {
				const modifiedSeries = {
					...series,
					// Add (LHS) to name only if we have both types and format is pct
					name: !hasPercentVars && !hasNonPercentVars && format === 'pct'
						? `${series.name} (LHS)`
						: series.name
				};
				leftAxisData.push(modifiedSeries);
			}
		});

		// Case 1: Only non-percent variables
		if (hasNonPercentVars) {
			if (format === 'dif') {
				return { leftAxisData: [], rightAxisData: lineData };
			}
			return { leftAxisData: lineData, rightAxisData: [] };
		}

		// Case 2: Only percent variables
		if (hasPercentVars) {
			if (format === 'lev') {
				return { leftAxisData: lineData, rightAxisData: [] };
			}
			return { leftAxisData: [], rightAxisData: lineData };
		}

		// Case 3: Both types present
		if (format === 'lev') {
			return { leftAxisData: lineData, rightAxisData: [] };
		}
		if (format === 'dif') {
			return { leftAxisData: [], rightAxisData: lineData };
		}
		if (format === 'pct') {
			return { leftAxisData, rightAxisData };
		}

		return { leftAxisData: [], rightAxisData: [] };
	};

	const { leftAxisData, rightAxisData } = filterDataByAxis();

	return (
		<Grid item xs={12}>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Box>
					<FormHelperText style={{ color: colourTitle }}>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							Click (several) Map Countries to display (compare) time series below:
							<MaterialTooltip
								title="The three dots on the Line Graph represents more than one variable is selected and shows the last one."
							>
								<InfoOutlined
									fontSize="small"
									style={{ marginLeft: '4px' }}
								/>
							</MaterialTooltip>
						</span>
					</FormHelperText>

				</Box>
				{loadingChatBot ? (
					<FormHelperText style={{ color: colourTitle }}>Loading...</FormHelperText>
				) : (
					<IconButton onClick={() => handleHelpIconClick()} style={{ padding: '0px' }}>
						<LiveHelpIcon data-testid="help-button" fontSize="small" />
					</IconButton>
				)}
			</Box>

			<ErrorBoundary>
				<HighchartsProvider Highcharts={Highcharts}>
					<HighchartsChart
						legend={{ enabled: true }}
						containerProps={{ style: { height: '75vh' } }}
						plotOptions={{
							series: {
								animation: animationClick && directionRef == 'play' ? false : true,
							},
						}}
						zoomType="x"
					>
						<Loading isLoading={loading}>Data loading...</Loading>
						<Title style={{ color: colourTitle, fontSize: '14px' }}>{title}</Title>
						<Subtitle style={{ color: '#786c3a' }}>
							{type === 'student'
								? scenarios[scenarioType]?.sizes.find((item: any) => item.id === 'cen')
									? 'Central Forecast(cen)'
									: scenarioSize.length > 1 ? scenarioTypes.find((item: any) => item.value === scenarioType)?.name : `Scenario: ${scenarioSize} - ${(() => {
										let text = scenarios[scenarioType].sizes.find(({ id }) => scenarioSize?.includes(id))?.text ?? '';
										text = text.substring(2, text.length) + ` - ${formatList[format.toUpperCase()]}`;
										return text;
									})()}`
								: BusinessScenarioSizes[scenarioType]?.sizes.find((item: any) => item.id === 'cen')
									? 'Central Forecast(cen)'
									: scenarioSize.length > 1 ? BusinessScenarioTypes.find((item: any) => item.value === scenarioType)?.name : `Scenario: ${scenarioSize} - ${(() => {
										let text =
											BusinessScenarioSizes[scenarioType].sizes.find(({ id }) => scenarioSize?.includes(id))?.text ??
											'';
										text = text.substring(2, text.length) + ` - ${formatList[format.toUpperCase()]}`;
										return text;
									})()}`}
						</Subtitle>
						<Chart zoomType="x" />
						<XAxis>
							{scenarioType === 'cen' && (
								<PlotLine value={2021} dashStyle="Dash" width={1.5} color="red" />
							)}
						</XAxis>

						{/* Left Y-Axis - only render if we have leftAxisData */}
						{/* Left Y-Axis - only render if we have leftAxisData */}
						{leftAxisData.length > 0 && (
							<YAxis zoomEnabled>
								<YAxis.Title>
									{format === 'lev' || format === 'LEV' || scenarioType === 'cen'
										? 'Forecast Level'
										: format === 'pct'
											? '% change from Base'
											: ''}
								</YAxis.Title>
								{leftAxisData.map(({ name, data, color, dashStyle, visible }, index) => (
									lineData.length === 1 ? (
										<React.Fragment key={index}>
											{directionRef == 'play' ? (
												<LineSeries
													pointStart={minYear}
													name={name}
													data={data}
													marker={{ enabled: false }}
													color={maxColour}
												/>
											) : (
												<AreaSeries
													pointStart={minYear}
													name={name}
													data={data}
													marker={{ enabled: false }}
													color={maxColour}
												/>
											)}
										</React.Fragment>
									) : (
										<LineSeries
											pointStart={minYear}
											key={index}
											name={name}
											data={data}
											color={color}
											visible={visible}
											dashStyle={dashStyle || 'Solid'}
											marker={{ enabled: false }}
										/>
									)
								))}
							</YAxis>
						)}

						{/* Right Y-Axis - only render if we have rightAxisData */}
						{rightAxisData.length > 0 && (
							<YAxis opposite zoomEnabled>
								<YAxis.Title>Absolute change from Base</YAxis.Title>
								{rightAxisData.map(({ name, data, color, dashStyle, visible }, index) => (
									lineData.length === 1 ? (
										<React.Fragment key={`diff-${index}`}>
											{directionRef == 'play' ? (
												<LineSeries
													pointStart={minYear}
													name={name}
													data={data}
													marker={{ enabled: false }}
													color={maxColour}
													yAxis={1}
												/>
											) : (
												<AreaSeries
													pointStart={minYear}
													name={name}
													data={data}
													marker={{ enabled: false }}
													color={maxColour}
													yAxis={1}
												/>
											)}
										</React.Fragment>
									) : (
										<LineSeries
											pointStart={minYear}
											key={`diff-${index}`}
											name={name}
											data={data}
											color={color}
											visible={visible}
											dashStyle={dashStyle || 'Solid'}
											marker={{ enabled: false }}
											yAxis={1}
										/>
									)
								))}
							</YAxis>
						)}
						<Tooltip />
					</HighchartsChart>
				</HighchartsProvider>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%'
				}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<InputLabel style={{ color: colourTitle, fontWeight: 'bold', fontSize: '12px', marginLeft: '30px' }}>
							Scenario Key
							<Info
								data-testid="info-button"
								fontSize="small"
								style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: '4px' }}
								onClick={() => openScenarioAbbr()}
							/>
						</InputLabel>
					</div>

					<div style={{ display: 'flex', alignItems: 'center' }}>
						<InputLabel style={{ color: colourTitle, fontWeight: 'bold', fontSize: '12px' }}>
							Central Forecast (Baseline) Overview
							<Info
								data-testid="info-button"
								fontSize="small"
								style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: '4px' }}
								onClick={() => openModal()}
							/>
						</InputLabel>
						<Modal isOpen={open} handleClose={handleClose} />
						<GHGMapCenModal isOpen={openGHGMap} handleClose={handleClose} />
						<EnergyMapCenModal isOpen={openEnergyMap} handleClose={handleClose} />
						<PortMapCenModal isOpen={openPortMap} handleClose={handleClose} />
					</div>

					<div style={{ display: 'flex', alignItems: 'center' }}>
						<InputLabel style={{ color: colourTitle, fontWeight: 'bold', fontSize: '12px' }}>
							Variable Key
							<Info
								data-testid="info-button"
								fontSize="small"
								style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: '4px' }}
								onClick={() => openSourcesMacroMapModalAbbrVar()}
							/>
						</InputLabel>
					</div>
				</div>
				<MapPageScenarioKeyModal
					isOpen={openScenarioAbbrModal}
					handleClose={() => {
						setOpenScenarioAbbrModal(false);
					}}
					color={colourTitle}
					background={colour}
					type={type}
				/>

				<MapPageVarAbbrModal
					isOpen={openSourcesVarAbbr}
					handleClose={() => {
						setopenSourcesVarAbbr(false);
					}}
					dataPrefix={dataPrefix}
					color={colourTitle}
					background={colour}
				/>

			</ErrorBoundary>
		</Grid>
	);
};

export default LineGraphMapPage;
