import React, { useEffect, useRef, useState } from 'react';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	IconButton,
	CircularProgress,
	Tooltip,
	LinearProgress,
	Typography,
	Button,
} from '@material-ui/core';
import { PlayCircleFilled, ReplayOutlined, Stop as StopIcon, InfoOutlined as InfoIcon } from '@material-ui/icons';
import Card from '../components/Card';
import { makeStyles, FormControlLabel, Checkbox, RadioGroup, Radio, Tooltip as MUITooltip } from '@material-ui/core';
import MacroTradeProdsSourcesModal from '../components/modals/MacroTradeProdsSourcesModal';
import fetchCSVData from '../utils/fetchCSVData';
import {
	countryCodesTradeProds,
	hs_countryMappings,
	regionCodeToName,
	regionColors,
	tradeDataType,
} from '../utils/countryMappingPie';
import {
	countryCountMap,
	productColors,
	productListHS,
	productListSITC,
	tradeData,
	treeMapParentListHS,
	treeMapParentListSITC,
} from '../utils/utilities';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { InfoOutlined } from '@material-ui/icons';
import Info from '@material-ui/icons/InfoOutlined';
import TradeTreemap from './MacroTradeProds/TradeTreemap';
import TradeLineGraph from './MacroTradeProds/TradeLineGraph';
import { hideDataTable } from '../utils/resetDataTable';
import BaseFolderContext from '../config/globalVariables';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { countrySubscriptionData, coverageSubscriptionData, dataImportExportSubscriptionData, lineGraphCountryCountSubscriptionData, productListSubscriptionData, yearSubscriptionData } from '../permissions/macroTradeProdsPermissions';

type TCSVData = Record<string, number> & { Country: string; Top5: string; Sum: number };

const MacroTradeProds = (): JSX.Element => {
	// State variables for managing data, UI state, and interactions
	const [loading, setLoading] = useState(false);
	const [loadingMsg, setLoadingMsg] = useState('Loading data...');
	// const [country, setCountry] = useState('USA');
	const [country, setCountry] = useState('GBR');
	// const [targetCountry, setTargetCountry] = useState<string>('ARG');
	const [targetCountry, setTargetCountry] = useState<string>('AUS');
	const [product, setProduct] = useState<string>('11');
	// const [year, setYear] = useState(1994);
	const [year, setYear] = useState(2021);
	const [tradeDataTypeDropdown, setTradeDataTypeDropdown] = useState<string>('WD');
	const [tradeDataDropdown, setTradeDataDropdown] = useState('exports');
	const [hsbProductSelectionData, setHsbProductSelectionData] = useState<any>([]);
	const [hsProductData, setHsProductData] = useState<any>([]);
	const [sitcProductData, setSitcProductData] = useState<any>([]);
	const [productData, setProductData] = useState<any>([]);
	const [dataByYear, setDataByYear] = useState<Record<number, any[]>>({});
	const [isBDFetchedForAllYears, setIsBDFetchedForAllYears] = useState(false);
	const [dataClass, setDataClass] = useState<string>('sitc');
	const [previousDataClass, setPreviousDataClass] = useState<string>('sitc');
	const [countryCount, setCountryCount] = useState<string>('5');
	const [allProductDataForWorld, setAllProductDataForWorld] = useState<any>([]);
	const [chartType, setChartType] = useState<string>('TM');
	const [levelWorldData, setLevelWorldData] = useState<any>([]);
	const [levelProductDataWorld, setLevelProductDataWorld] = useState<any>([]);
	const [open, setOpen] = useState(false);
	const [isAnimationClicked, setIsAnimationClicked] = useState(false);
	const [totalValue, setTotalValue] = useState('');
	const [fetchingAllYears, setFetchingAllYears] = useState(false);
	const [progress, setProgress] = useState(0);
	const directionRef = useRef<'pause' | 'play' | ''>('');
	const colourTitle = 'rgb(84, 77, 160)';
	const cancelFetchRef = useRef(false); // Ref to manage cancellation of fetch operations
	const projectionYearStartDate = 2022; //Change the colour to red for year when projected data starts
	const [excludeProductsCheckbox, setExcludeProductsCheckbox] = useState<boolean>(false); // Checkbox state
	const [selectedExcludedProducts, setSelectedExcludedProducts] = useState<number[]>([]); // Multi-select dropdown state
	const [selectedExcludedRegions, setSelectedExcludedRegions] = useState<string[]>([]); // Multi-select dropdown state
	//const [showYearLimitMessage, setShowYearLimitMessage] = useState(false); // State to control visibility of the message
	const [showCoverageTooltipOnClick, setShowCoverageTooltipOnClick] = useState(false);
	const [showClassificationTooltipOnClick, setShowClassificationTooltipOnClick] = useState(false);
	const [showDataTooltipOnClick, setShowDataTooltipOnClick] = useState(false);
	const [showChartTypeTooltipOnClick, setShowChartTypeTooltipOnClick] = useState(false);
	const [showCountriesTooltipOnClick, setShowCountriesTooltipOnClick] = useState(false);
	const [showExcludeProductsTooltipOnClick, setShowExcludeProductsTooltipOnClick] = useState(false);

	const handleCoverageTooltipOpen = () => {
		setShowCoverageTooltipOnClick(true);
	};

	const handleClassificationTooltipOpen = () => {
		setShowClassificationTooltipOnClick(true);
	};

	const handleDataTooltipOpen = () => {
		setShowDataTooltipOnClick(true);
	};

	const handleChartTypeTooltipOpen = () => {
		setShowChartTypeTooltipOnClick(true);
	};

	const handleCountriesTooltipOpen = () => {
		setShowCountriesTooltipOnClick(true);
	}

	const handleExcludeProductsTooltipOpen = () => {
		setShowExcludeProductsTooltipOnClick(true);
	}

	const handleSelectChange =
		(setter: React.Dispatch<React.SetStateAction<any>>) => (event: React.ChangeEvent<{ value: unknown }>) => {
			setter(event.target.value); // Function to handle dropdown selections
		};

	// Fetch and parse tab-separated data from a URL into readable form
	const fetchAndParseTabData = async (url: string) => {
		try {
			const response = await fetch(url);
			if (!response.ok) throw new Error(`Error fetching data: ${response.statusText}`);
			const textData = await response.text();
			const [headers, ...lines] = textData.split('\n');
			const headerArray = headers.split('\t');

			return lines.map((line) => {
				const values = line.split('\t');
				return headerArray.reduce((obj, header, index) => {
					obj[header.trim()] = values[index]?.trim();
					return obj;
				}, {} as Record<string, string>);
			});
		} catch (error) {
			console.error('Error fetching or parsing data:', error);
			return [];
		}
	};

	// Fetch initial data for HS and SITC products from tab files
	const fetchInitialData = async () => {
		try {
			setLoading(true);
			const hsProductUrl = 'https://risknz.mef-uk.com/data/MacroTradeProds_hs/hs_product.tab';
			const sitcProductUrl = 'https://risknz.mef-uk.com/data/MacroTradeProds_sitc/sitc_product.tab';
			const [hsProductParsed, sitcProductParsed] = await Promise.all([
				fetchAndParseTabData(hsProductUrl),
				fetchAndParseTabData(sitcProductUrl),
			]);

			setHsProductData(hsProductParsed);
			setSitcProductData(sitcProductParsed);
		} catch (error) {
			console.error('Error fetching initial data:', error);
		} finally {
			setLoading(false);
		}
	};

	// Load initial data when the component mounts
	useEffect(() => {
		fetchInitialData();
	}, []);

	// Fetch data for product details which contains parent_id, parent names and child names
	useEffect(() => {
		const fetchTotalDataForWorld = async () => {
			try {
				if (tradeDataTypeDropdown === 'WD') {
					setLoading(true);
					setLoadingMsg('Loading data...');
					const url = `data/MacroTradeProds_${dataClass}/country_${dataClass}product2digit_year_SLIM.CSV?dummy=${Math.random()}`;
					const totalWorldData = await fetchCSVData<TCSVData>(url);
					setHsbProductSelectionData(totalWorldData);
				}
			} catch (e) {
				console.error(`No data available for year ${year}`);
			} finally {
				setLoading(false);
			}
		};
		fetchTotalDataForWorld();
	}, [tradeDataTypeDropdown, dataClass]);

	const [isDataCombined, setIsDataCombined] = useState(false);

	// Fetch product data which was modeled from the python file (ProcDTAsitcParentTotalsProduct_year.ipynb) for all countries and products till 2021
	// Also fetched data for world with predicted values of all parent products till 2050.
	useEffect(() => {
		const fetchTotalDataForProducts = async () => {
			try {
				if (tradeDataTypeDropdown === 'WD') {
					setLoading(true);
					setLoadingMsg('Loading data...');

					const urlWorld = `csv_scen/${BaseFolderContext.baseCSVFolder}/MacroTradeProds/cen/cen-GV_XMn$${dataClass}AdjLEV_WD.CSV?dummy=${Math.random()}`;
					const url = `data/MacroTradeProds_${dataClass}/GlobalTotalProducts_${dataClass}_Year.CSV?dummy=${Math.random()}`;

					let [levelProductDataCsv, totalProductData] = await Promise.all([
						fetchCSVData<TCSVData>(urlWorld),
						fetchCSVData<TCSVData>(url),
					]);

					levelProductDataCsv = levelProductDataCsv.map((item: any) => {
						const newItem: any = { Date: item.Date }; // Initialize with known key
						Object.keys(item).forEach((key) => {
							if (key.startsWith('Xn')) {
								const newKey = key.replace(/(sum)a/, 'sum').replace(/\$a/, '$');
								newItem[newKey] = item[key] as number; // Safe casting, assuming all non-date values are numbers
							} else {
								newItem[key] = item[key];
							}
						});
						return newItem;
					});

					setLevelProductDataWorld(levelProductDataCsv);
					setProductData(totalProductData);
					setIsDataCombined(false);
				}
			} catch (e) {
				console.error(`No data available for year ${year}`);
			} finally {
				setLoading(false);
			}
		};

		fetchTotalDataForProducts();
	}, [tradeDataTypeDropdown, dataClass]);

	/**  Fetch data for parent products using predicted data for each country till 2050.
	 *Here Predicted data for all countries has been fetched together and then combined and stored together.
	 * */
	useEffect(() => {
		const fetchTotalDataForProductsAfter2021 = async () => {
			try {
				if (tradeDataTypeDropdown === 'WD') {
					setLoading(true);
					setLoadingMsg('Loading data...');

					const urls = Object.values(countryCodesTradeProds).map(
						(countryCode) =>
							`csv_scen/${BaseFolderContext.baseCSVFolder}/MacroTradeProds/cen/cen-GV_XMn$${dataClass}AdjLEV${countryCode}.CSV?dummy=${Math.random()}`
					);
					const countryDataPromises = urls.map((url) => fetchCSVData<TCSVData>(url));
					const allCountriesData = await Promise.all(countryDataPromises);
					let combinedData = allCountriesData.flat();

					combinedData = combinedData.map((item: any) => {
						const newItem: any = { Date: item.Date }; // Initialize with known key
						Object.keys(item).forEach((key) => {
							if (key.startsWith('Xn')) {
								const newKey = key.replace(/(sum)a/, 'sum').replace(/\$a/, '$');
								newItem[newKey] = item[key] as number; // Safe casting, assuming all non-date values are numbers
							} else {
								newItem[key] = item[key];
							}
						});
						return newItem;
					});

					setAllProductDataForWorld(combinedData);
					setIsDataCombined(false);
				}
			} catch (e) {
				console.error(`No data available.`);
			} finally {
				setLoading(false);
			}
		};

		fetchTotalDataForProductsAfter2021();
	}, [tradeDataTypeDropdown, dataClass]);

	// Combine the world product data for each countries which we combined together with the product data modeled from the .ipynb file
	useEffect(() => {
		if (levelProductDataWorld && allProductDataForWorld && !isDataCombined) {
			const formattedData = convertToProductFormat(levelProductDataWorld, allProductDataForWorld);

			setProductData((prevProductData: any) => {
				if (!isDataCombined) {
					const combinedProductData = [...prevProductData, ...formattedData];
					return combinedProductData;
				}
				return prevProductData;
			});
			setIsDataCombined(true);
		}
	}, [levelProductDataWorld, allProductDataForWorld, tradeDataTypeDropdown, dataClass]);

	/**   Converted the countries predicted data for products to the same format as product data.
	 * Used world predicted data as well to do the calculations to find the export and import values of products after years 2021.
	 * Also added the world share for particular products to the product data.*/
	const convertToProductFormat = (levelProductDataWorld: any, levelWorldDataCountry: any) => {
		const formattedData: any = [];
		const entryMap: { [key: string]: any } = {};

		const reverseCountryCodes = Object.fromEntries(
			Object.entries(countryCodesTradeProds).map(([key, value]) => [value, key])
		);

		levelProductDataWorld.forEach((worldData: any) => {
			const year = worldData.Date;

			if (year > 2021) {
				Object.keys(worldData).forEach((key) => {
					if (key.startsWith('Xn') || key.startsWith('Mn')) {
						const type = key.substring(0, 2);
						const parentCodeLocation = key.substring(2);
						const parentIdMatch = parentCodeLocation.match(/^\d+/);
						const parentId = parentIdMatch ? parseInt(parentIdMatch[0], 10) : null;

						if (parentId !== null) {
							const parentName = dataClass === 'hs' ? productListHS[parentId] : productListSITC[parentId];
							const totalWorldValue = worldData[key];
							const value = totalWorldValue * 1_000_000_000;
							const entryKey = `${year}_WD_${parentId}`;

							if (!entryMap[entryKey]) {
								entryMap[entryKey] = {
									year,
									location_code: 'WD',
									parent_id: parentId,
									parent_name: parentName,
									export_value: 0,
									import_value: 0,
									export_share: 100,
									import_share: 100,
								};
							}

							if (type === 'Xn') {
								entryMap[entryKey].export_value = value;
							} else if (type === 'Mn') {
								entryMap[entryKey].import_value = value;
							}
						}
					}
				});
			}
		});

		levelProductDataWorld.forEach((worldData: any) => {
			const year = worldData.Date;

			if (year >= 1994) {
				Object.keys(worldData).forEach((key) => {
					if (key.includes('sum')) {
						const sumType = key.substring(0, 2); // Get 'Xn' or 'Mn'
						const totalSumValue = worldData[key];
						const sumValue = totalSumValue * 1_000_000_000;
						const sumEntryKey = `${year}_WD_12`; // Parent ID 12 for 'Goods Total'

						if (!entryMap[sumEntryKey]) {
							entryMap[sumEntryKey] = {
								year,
								location_code: 'WD',
								parent_id: 12,
								parent_name: 'Goods Total',
								export_value: 0,
								import_value: 0,
								export_share: 100,
								import_share: 100,
							};
						}

						if (sumType === 'Xn') {
							entryMap[sumEntryKey].export_value = sumValue;
						} else if (sumType === 'Mn') {
							entryMap[sumEntryKey].import_value = sumValue;
						}
					}
				});
			}
		});

		// Process country data for years > 2021
		levelWorldDataCountry.forEach((countryData: any) => {
			const year = countryData.Date;

			if (year > 2021) {
				Object.keys(countryData).forEach((key) => {
					if (key.startsWith('Xn') || key.startsWith('Mn')) {
						const type = key.substring(0, 2);
						const parentCodeLocation = key.substring(2);
						if (parentCodeLocation) {
							const parentIdMatch = parentCodeLocation.match(/^\d+/);
							const parentId = parentIdMatch ? parseInt(parentIdMatch[0], 10) : null;

							if (parentId !== null) {
								const locationSuffix = parentCodeLocation.split('$')[1];
								if (locationSuffix && locationSuffix.startsWith('sum')) {
									return; // Skip the current iteration
								}
								const locationCode = reverseCountryCodes[`${locationSuffix}`];
								const parentName = dataClass === 'hs' ? productListHS[parentId] : productListSITC[parentId];
								const correspondingWorldData = levelProductDataWorld.find((worldData: any) => worldData.Date === year);
								if (!correspondingWorldData) return;
								const totalWorldValue = correspondingWorldData[`${type}${parentId}$_WD`];
								const countryValue = countryData[key];
								const share = (countryValue / totalWorldValue) * 100;
								//Multiplied this value by billion so it is in dollar value only to make it consistent with the productData value
								const value = (share / 100) * totalWorldValue * 1_000_000_000;
								const entryKey = `${year}_${locationCode}_${parentId}`;
								if (!entryMap[entryKey]) {
									entryMap[entryKey] = {
										year,
										location_code: locationCode,
										parent_id: parentId,
										parent_name: parentName,
										export_value: 0,
										import_value: 0,
										export_share: 0,
										import_share: 0,
									};
								}
								if (type.startsWith('Xn')) {
									entryMap[entryKey].export_value = value;
									entryMap[entryKey].export_share = share;
								} else if (type.startsWith('Mn')) {
									entryMap[entryKey].import_value = value;
									entryMap[entryKey].import_share = share;
								}
							}
						}
					}
				});
			}
		});

		// Process country data for years >= 1994 for sum fields
		levelWorldDataCountry.forEach((countryData: any) => {
			const year = countryData.Date;
			// New method use level .csv files to calculate the Goods Total values.
			if (year >= 1994) {
				Object.keys(countryData).forEach((key) => {
					if (key.includes('sum')) {
						const sumType = key.substring(0, 2); // Get 'Xn' or 'Mn'
						const totalSumValue = countryData[key];
						const sumValue = totalSumValue * 1_000_000_000;
						const parentCodeLocation = key.substring(2);

						// Adjust locationSuffix extraction to handle '$' separator
						const locationSuffix = parentCodeLocation.substring(parentCodeLocation.indexOf('$sum') + 4);
						const locationCode = reverseCountryCodes[locationSuffix] || '';

						const correspondingWorldData = levelProductDataWorld.find((worldData: any) => worldData.Date === year);
						if (!correspondingWorldData) return;
						const worldSumValue = correspondingWorldData[`${sumType}09$sum_WD`];
						const share = (totalSumValue / worldSumValue) * 100;
						const sumEntryKey = `${year}_${locationCode}_12`; // Parent ID 12 for 'Goods Total'

						if (!entryMap[sumEntryKey]) {
							entryMap[sumEntryKey] = {
								year,
								location_code: locationCode,
								parent_id: 12,
								parent_name: 'Goods Total',
								export_value: 0,
								import_value: 0,
								export_share: 0,
								import_share: 0,
							};
						}

						if (sumType.startsWith('Xn')) {
							entryMap[sumEntryKey].export_value = sumValue;
							entryMap[sumEntryKey].export_share = share;
						} else if (sumType.startsWith('Mn')) {
							entryMap[sumEntryKey].import_value = sumValue;
							entryMap[sumEntryKey].import_share = share;
						}
					}
				});
			}
		});

		formattedData.push(...Object.values(entryMap));

		return formattedData;
	};

	// Fetch world data after 2021 for a specific product and country (predicted)
	useEffect(() => {
		const fetchTotalDataForWorldAfter2021 = async () => {
			try {
				if (tradeDataTypeDropdown === 'WD' && product == '11') {
					setLoading(true);
					setLoadingMsg('Loading data...');
					const url = `csv_scen/bf_2024Q1/MacroTradeProds/cen/cen-GV_XMn$${dataClass}AdjLEV${countryCodesTradeProds[country]
						}.CSV?dummy=${Math.random()}`;
					const levelWorldDataCsv = await fetchCSVData<TCSVData>(url);
					setLevelWorldData(levelWorldDataCsv);
				}
			} catch (e) {
				console.error(`No data available for year ${year}`);
			} finally {
				setLoading(false);
			}
		};
		fetchTotalDataForWorldAfter2021();
	}, [tradeDataTypeDropdown, dataClass, year, country, product]);

	// Fetch tree map data for a specific year
	const fetchDataForYear = async (year: number) => {
		try {
			setLoading(true);
			setLoadingMsg('Loading data...');
			let url = '';
			if (tradeDataTypeDropdown === 'BD') {
				url = `data/MacroTradeProds_${dataClass}/country_partner_${dataClass}product2digit_${year}.CSV?dummy=${Math.random()}`;
			}
			const countryPartner = await fetchCSVData<TCSVData>(url);
			return countryPartner;
		} catch (e) {
			console.error(`No data available for year ${year}`);
			return [];
		}
	};

	// Fetch data for all years from 1994 to 2050
	const fetchDataForAllYears = async () => {
		if (isBDFetchedForAllYears && dataClass === previousDataClass) return;

		setFetchingAllYears(true);
		setLoading(true);
		setLoadingMsg('Fetching data for all years...');
		const data: Record<number, any[]> = {};

		const startYear = 1994;
		const endYear = 2021;
		const totalYears = endYear - startYear + 1;

		cancelFetchRef.current = false;

		for (let year = startYear; year <= endYear; year++) {
			if (cancelFetchRef.current) {
				break;
			}
			const yearData = await fetchDataForYear(year);
			data[year] = yearData;
			const completedYears = year - startYear + 1;
			const progress = (completedYears / totalYears) * 100;
			setProgress(progress);
		}

		if (!cancelFetchRef.current) {
			setDataByYear(data);
			setIsBDFetchedForAllYears(true);
			setPreviousDataClass(dataClass);
		}
		setLoading(false);
		setFetchingAllYears(false);
	};

	// Handle fetching of data based on the selected year and data type
	useEffect(() => {
		const fetchData = async () => {
			if (dataByYear[year] && dataClass === previousDataClass) {
				setLoading(true);
				setHsbProductSelectionData(dataByYear[year]);
				setLoading(false);
			} else if (tradeDataTypeDropdown === 'BD' && year > 2021) {
				setLoading(true);
				const yearData = await fetchDataForYear(2021);
				setHsbProductSelectionData(yearData);
				setLoading(false);
			} else if (tradeDataTypeDropdown === 'BD' && (!isBDFetchedForAllYears || dataClass !== previousDataClass)) {
				setLoading(true);
				const yearData = await fetchDataForYear(year);
				setHsbProductSelectionData(yearData);
				setLoading(false);
			} else if (tradeDataTypeDropdown === 'BD' && isBDFetchedForAllYears && dataClass === previousDataClass) {
				setHsbProductSelectionData(dataByYear[year]);
			}
		};
		fetchData();
	}, [year, dataByYear, isBDFetchedForAllYears, dataClass, tradeDataTypeDropdown]);

	// Reset product selection when trade data type changes
	useEffect(() => {
		if (tradeDataTypeDropdown === 'BD') {
			setProduct('11');
		}
	}, [tradeDataTypeDropdown]);

	useEffect(() => {
		if (tradeDataTypeDropdown === 'BD' && year > 2021) {
			setTotalValue('$0 bn');
		}
	}, [tradeDataTypeDropdown, year]);

	// Handle play control for the animation
	const handleAnimationControlPlay = async () => {
		if (tradeDataTypeDropdown === 'BD' && (!isBDFetchedForAllYears || dataClass !== previousDataClass)) {
			await fetchDataForAllYears();
		}
		setIsAnimationClicked(true);
		directionRef.current = 'play';

		for (let currentYear = year; currentYear <= 2050; currentYear++) {
			if (directionRef.current !== 'play') break;
			// if (tradeDataTypeDropdown === 'BD' && currentYear > 2021) {
			// 	setShowYearLimitMessage(true);
			// 	handleAnimationControlStop();
			// 	setTimeout(() => {
			// 		setShowYearLimitMessage(false); // Hide the message after 5 seconds
			// 	}, 5000);
			// 	break;
			// }
			setYear(currentYear);
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}

		directionRef.current = '';
	};

	// Handle stop control for the animation
	const handleAnimationControlStop = () => {
		directionRef.current = 'pause';
		setIsAnimationClicked(false);
	};

	// Handle replay control for the animation
	const handleAnimationControlReplay = () => {
		setIsAnimationClicked(false);
		directionRef.current = 'pause';
		setYear(2021);
		directionRef.current = 'play';
	};

	const useStyles = makeStyles({
		mark: { color: 'rgb(84, 77, 160)', fontWeight: 'bold' },
		marked: { marginBottom: '0px' },
	});

	const classes = useStyles();

	const openModal = () => setOpen(true); // Function to open the sources modal
	const handleClose = () => {
		setOpen(false);
		setShowCoverageTooltipOnClick(false);
		setShowClassificationTooltipOnClick(false);
		setShowDataTooltipOnClick(false);
		setShowChartTypeTooltipOnClick(false);
		setShowCountriesTooltipOnClick(false);
		setShowExcludeProductsTooltipOnClick(false);
	}

	// Reset all controls and data
	const handleAllReset = () => {
		setFetchingAllYears(false);
		cancelFetchRef.current = true;
		setLoading(false);
		setProgress(0);
		directionRef.current = 'pause';

		setYear(2021);
		setCountry('GBR');
		setDataClass('sitc');
		setTradeDataDropdown('exports');
		setTradeDataTypeDropdown('WD');
		setProduct('11');
		setChartType('TM');
		setExcludeProductsCheckbox(false);
		setSelectedExcludedProducts([]);
		setSelectedExcludedRegions([]);

		hideDataTable(1)
	};

	// Generate marks for the slider based on the selected year range
	const getSliderMarks = () => {
		const startYear = 1994;
		const endYear = 2050;

		if (endYear === 2050) {
			const marks = [];
			marks.push({ value: startYear, label: String(startYear) }); // Start year

			for (let year = 2000; year <= 2045; year += 5) {
				marks.push({ value: year, label: String(year) });
			}

			marks.push({ value: endYear, label: String(endYear) }); // End year (2050)
			return marks;
		} else {
			const numberOfMarks = 10;
			const interval = Math.floor((endYear - startYear) / (numberOfMarks - 1));

			const marks = [];
			for (let year = startYear; year <= endYear; year += interval) {
				marks.push({ value: year, label: String(year) });
			}

			if (marks[marks.length - 1].value !== endYear) {
				marks.push({ value: endYear, label: String(endYear) });
			}

			return marks;
		}
	};

	useEffect(() => {
		setSelectedExcludedProducts([]); // Reset selected products to blank
		setSelectedExcludedRegions([]);
		setExcludeProductsCheckbox(false);
	}, [dataClass, tradeDataTypeDropdown]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', width: '100%' }}>
			<div style={{ display: 'flex', marginBottom: '3px', alignItems: 'center' }}>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '40px' }}>
					<div style={{ marginBottom: '0px' }}>
						Classification:
						<Tooltip
							title="Standard Industrial Trade Classification (sitc) or Harmonised System (hs) - see Data & Sources below for details. NB projections to 2050 only available for sitc."
							open={showClassificationTooltipOnClick}
							onOpen={handleClassificationTooltipOpen}
							onClose={handleClose}
						>
							<InfoOutlined
								fontSize="small"
								onClick={(e) => {
									e.stopPropagation();
									setShowClassificationTooltipOnClick(!showClassificationTooltipOnClick);
								}}
							/>
						</Tooltip>
					</div>
					<RadioGroup
						onChange={handleSelectChange(setDataClass)}
						row
						name="radio-buttons-group"
						value={dataClass}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<FormControlLabel value="sitc" control={<Radio />} label="sitc" />
						<FormControlLabel value="hs" control={<Radio />} label="hs" disabled={true} />
					</RadioGroup>
				</div>
				<Card style={{ flexGrow: 1 }}>
					<Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
						<Grid item>
							<FormControl>
								<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Country</InputLabel>
								<Select
									value={country}
									onChange={handleSelectChange(setCountry)}
									MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
									disabled={chartType !== 'TM' || product !== '11'}
								>
									{Object.entries(hs_countryMappings).map(([key, value]) => {
										const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
											//										countrySubscriptionData[key]?.countryStatus === 'Unsubscribed';
											countrySubscriptionData[key]?.countryStatus === 'Subscribed';
										const isBilateralDisabled = tradeDataTypeDropdown === 'BD' && key === targetCountry;

										return (
											<MenuItem
												key={key}
												value={key}
												disabled={isUnsubscribed || isBilateralDisabled}
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													...(isUnsubscribed && {
														opacity: 1,
														color: 'rgba(0, 0, 0, 0.38)'
													})
												}}
											>
												<span>{value}</span>
												{isUnsubscribed && (
													<LockOutlinedIcon
														fontSize="small"
														style={{
															fontSize: '16px',
															marginLeft: '6px'
														}}
													/>
												)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl>
								<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Year</InputLabel>
								<Select
									value={year}
									onChange={handleSelectChange(setYear)}
									MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
									disabled={chartType !== 'TM'}
								>
									{Array.from({ length: 2051 - 1994 }, (_, i) => 1994 + i).map((year) => {
										const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
											yearSubscriptionData[year] === 'Unsubscribed';

										return (
											<MenuItem
												key={year}
												value={year}
												disabled={isUnsubscribed}
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													color: year >= projectionYearStartDate ? 'red' : 'black',
													...(isUnsubscribed && {
														opacity: 1,
														color: 'rgba(0, 0, 0, 0.38)'
													})
												}}
											>
												<span>{year}</span>
												{isUnsubscribed && (
													<LockOutlinedIcon
														fontSize="small"
														style={{
															fontSize: '16px',
															marginLeft: '6px'
														}}
													/>
												)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item>
							<FormControl>
								<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
									Data{' '}
									<Tooltip
										title={
											<>
												In order to ensure consistency at a &quot;Global&quot; level between Total Exports and Imports
												(of Products) over the forecast period, all raw, COMTRADE Export data has been adjusted after
												2021.
											</>
										}
										open={showDataTooltipOnClick}
										onOpen={handleDataTooltipOpen}
										onClose={handleClose}
									>
										<InfoOutlined fontSize="small"
											onClick={(e) => {
												e.stopPropagation();
												setShowDataTooltipOnClick(!showDataTooltipOnClick);
											}} />
									</Tooltip>
								</InputLabel>
								<Select
									value={tradeDataDropdown}
									onChange={handleSelectChange(setTradeDataDropdown)}
									MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
								>
									{Object.entries(tradeData).map(([key, value]) => {
										const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
											dataImportExportSubscriptionData[key] === 'Unsubscribed';
										return (
											<MenuItem key={key} value={key} disabled={isUnsubscribed}>
												<span>{value}</span>
												{isUnsubscribed && (
													<LockOutlinedIcon
														fontSize="small"
														style={{
															fontSize: '16px',
															marginLeft: '6px'
														}}
													/>
												)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Card>
				<Card style={{ flexGrow: 1, marginLeft: '20px' }}>
					<Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
						<Grid item>
							<FormControl>
								<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
									Coverage{' '}
									<Tooltip
										title={
											<>
												World &quot;Total&quot; refers to Exports or Imports to <i>all</i> countries in aggregate,
												whereas Bilateral Trade shows Exports to a <i>specific</i> country. NB no bilateral trade exists
												for &apos;Services&apos;.
												<br />
												<br />
												Bilateral data has been projected to 2050 so it is consistent with &quot;World-Total&quot; trade
												projections that come from MEF&apos;s tpGEM model.
											</>
										}
										open={showCoverageTooltipOnClick}
										onOpen={handleCoverageTooltipOpen}
										onClose={handleClose}
									>
										<InfoOutlined
											fontSize="small"
											onClick={(e) => {
												e.stopPropagation();
												setShowCoverageTooltipOnClick(!showCoverageTooltipOnClick);
											}}
										/>
									</Tooltip>
								</InputLabel>
								<Select
									value={tradeDataTypeDropdown}
									onChange={handleSelectChange(setTradeDataTypeDropdown)}
									MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
									disabled={chartType !== 'TM'}
								>
									{Object.entries(tradeDataType).map(([key, value]) => {
										const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
											coverageSubscriptionData[key] === 'Unsubscribed';
										return (
											<MenuItem key={key} value={key} disabled={isUnsubscribed}>
												<span>{value}</span>
												{isUnsubscribed && (
													<LockOutlinedIcon
														fontSize="small"
														style={{
															fontSize: '16px',
															marginLeft: '6px'
														}}
													/>
												)}
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						</Grid>
						{tradeDataTypeDropdown === 'WD' && (
							<Grid item>
								<FormControl>
									<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Products</InputLabel>
									<Select
										value={product}
										onChange={handleSelectChange(setProduct)}
										MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
									>
										{['11', '12', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((key) => {
											const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
												productListSubscriptionData[key] === 'Unsubscribed';
											const value = (dataClass === 'hs' ? productListHS : productListSITC)[key];
											if (chartType === 'LG' && key === '11') {
												return null;
											}
											return (
												<MenuItem
													key={key}
													value={key}
													disabled={isUnsubscribed}
													style={{
														fontWeight: ['10', '11', '12'].includes(key) ? 'bold' : 'normal',
														marginLeft: ['10', '11', '12'].includes(key) ? '0' : '16px',
														color: ['11'].includes(key) ? 'black' : colourTitle,
													}}
												>
													<span>{value}</span>
													{isUnsubscribed && (
														<LockOutlinedIcon
															fontSize="small"
															style={{
																fontSize: '16px',
																marginLeft: '6px'
															}}
														/>
													)}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						)}
						{tradeDataTypeDropdown === 'BD' && (
							<Grid item>
								<FormControl>
									<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Partner</InputLabel>
									<Select
										value={targetCountry}
										onChange={handleSelectChange(setTargetCountry)}
										MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
									>
										{Object.entries(hs_countryMappings).map(([key, value]) => {
											const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
												countrySubscriptionData[key]?.partnerStatus === 'Subscribed';

											return (
												<MenuItem
													key={key}
													value={key}
													disabled={isUnsubscribed || key === country}
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
														...(isUnsubscribed && {
															opacity: 1,
															color: 'rgba(0, 0, 0, 0.38)'
														})
													}}
												>
													<span>{value}</span>
													{isUnsubscribed && (
														<LockOutlinedIcon
															fontSize="small"
															style={{
																fontSize: '16px',
																marginLeft: '6px'
															}}
														/>
													)}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</Card>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginLeft: '40px',
					}}
				>
					<div style={{ marginBottom: '0px' }}>
						Chart Type:
						<Tooltip title="Tree Map shows an annual, comparative trade snapshot, whereas the Line Graph illustrates secular trends"
							open={showChartTypeTooltipOnClick}
							onOpen={handleChartTypeTooltipOpen}
							onClose={handleClose}>
							<InfoOutlined fontSize="small"
								onClick={(e) => {
									e.stopPropagation();
									setShowChartTypeTooltipOnClick(!showChartTypeTooltipOnClick);
								}} />
						</Tooltip>
					</div>
					<RadioGroup
						onChange={handleSelectChange(setChartType)}
						row
						name="radio-buttons-group"
						value={chartType}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<FormControlLabel
							value="TM"
							control={<Radio />}
							label={<span style={{ fontWeight: 'bold' }}>Tree Map</span>}
						/>
						<FormControlLabel
							value="LG"
							control={<Radio />}
							label={
								<span style={{ color: product === '11' ? 'rgba(0, 0, 0, 0.38)' : colourTitle, fontWeight: 'bold' }}>
									Line Graph
									<Tooltip title="Line graphs are accessible once a Product is selected from 'Products' dropdown. NB not available for 'All Products (G&S).'">
										<InfoOutlined fontSize="small" />
									</Tooltip>
								</span>
							}
							disabled={product === '11'}
						/>
					</RadioGroup>
				</div>

				<Card style={{ flexGrow: 1, marginLeft: '20px' }}>
					<Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
						<Grid item>
							<FormControl>
								<InputLabel style={{ color: 'rgba(0, 0, 0, 0.54)', display: 'flex', alignItems: 'center' }}>
									Countries
									<Tooltip title="The Top 5|10|15 countries are ordered according to their Global Trade shares in the latest data year (2021)"
										open={showCountriesTooltipOnClick}
										onOpen={handleCountriesTooltipOpen}
										onClose={handleClose}>
										<Info data-testid="info-button" fontSize="small" style={{ cursor: 'pointer', marginLeft: '1px' }}
											onClick={(e) => {
												e.stopPropagation();
												setShowCountriesTooltipOnClick(!showCountriesTooltipOnClick);
											}} />
									</Tooltip>
								</InputLabel>
								<Select
									value={countryCount}
									onChange={handleSelectChange(setCountryCount)}
									MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
									disabled={chartType !== 'LG'}
								>
									{Object.entries(countryCountMap).map(([key, value]) => {
										const isUnsubscribed = localStorage.getItem('subscription') === 'Unsubscribed' &&
											lineGraphCountryCountSubscriptionData[key] === 'Unsubscribed';
										return (
											<MenuItem key={key} value={key} disabled={isUnsubscribed}>
												<span>{value}</span>
												{isUnsubscribed && (
													<LockOutlinedIcon
														fontSize="small"
														style={{
															fontSize: '16px',
															marginLeft: '6px'
														}}
													/>
												)}
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Card>
				<div
					style={{
						marginLeft: '20px',
					}}
				>
					<Button
						onClick={handleAllReset}
						size="small"
						style={{
							padding: 0,
							height: '20px',
							minWidth: '20px',
							marginTop: '10px',
						}}
					>
						<MUITooltip title="Reset Tree Map and Line Graph">
							<RotateLeftIcon fontSize="small" />
						</MUITooltip>
					</Button>
				</div>
			</div>
			{chartType === 'TM' && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: '0px',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '5px' }}>
						<div style={{ marginTop: '20px' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={excludeProductsCheckbox}
										onChange={() => setExcludeProductsCheckbox(!excludeProductsCheckbox)}
									/>
								}
								label={
									<span>
										{product === '11' ? 'Exclude Products:' : 'Exclude Regions:'}
										<Tooltip title="Selecting 'Exclude Product/Region' removes the chosen products or regions from the tree map."
											open={showExcludeProductsTooltipOnClick}
											onOpen={handleExcludeProductsTooltipOpen}
											onClose={handleClose}>
											<InfoOutlined fontSize="small"
												onClick={(e) => {
													e.stopPropagation();
													e.preventDefault();
													setShowExcludeProductsTooltipOnClick(!showExcludeProductsTooltipOnClick);
												}} />
										</Tooltip>
									</span>
								}
							/>
						</div>

						{excludeProductsCheckbox && (
							<div>
								<FormControl style={{ marginLeft: '2px', minWidth: '190px' }}>
									<InputLabel id="select-exclude-label" style={{ fontSize: '0.875rem' }}>
										{product === '11' ? 'Select Products to Exclude' : 'Select Regions to Exclude'}
									</InputLabel>
									<Select
										labelId="select-exclude-label"
										multiple
										value={product === '11' ? selectedExcludedProducts : selectedExcludedRegions}
										onChange={(event) => {
											const selectedValues = event.target.value as string[];

											if (product === '11') {
												// Toggle product selection
												const newSelectedProducts = selectedExcludedProducts.includes(
													Number(selectedValues[selectedValues.length - 1])
												)
													? selectedExcludedProducts.filter(
														(id) => id !== Number(selectedValues[selectedValues.length - 1])
													)
													: [...selectedExcludedProducts, Number(selectedValues[selectedValues.length - 1])];

												// Limit the selection to 9 if tradeDataTypeDropdown === 'BD', else 10
												const maxSelectionLimit = tradeDataTypeDropdown === 'BD' ? 9 : 10;

												if (newSelectedProducts.length <= maxSelectionLimit) {
													setSelectedExcludedProducts(newSelectedProducts);
												}
											} else {
												// Toggle region selection using the entire selected values array
												const newSelectedRegions = selectedValues;
												if (newSelectedRegions.length <= 4) {
													setSelectedExcludedRegions(newSelectedRegions);
												}
											}
										}}
										renderValue={(selected: any) => {
											if (product === '11') {
												const displayedNames = selected.map(
													(selectedId: number) =>
														(dataClass === 'sitc' ? treeMapParentListSITC : treeMapParentListHS).find(
															(product: any) => product.id === selectedId
														)?.name
												);
												return <span style={{ fontSize: '0.7rem' }}>{displayedNames.join(', ')}</span>;
											} else {
												const displayedRegions = selected.map((regionCode: string) => regionCodeToName[regionCode]);
												return <span style={{ fontSize: '0.7rem' }}>{displayedRegions.join(', ')}</span>;
											}
										}}
									>
										{product === '11'
											? (dataClass === 'sitc' ? treeMapParentListSITC : treeMapParentListHS)
												// Filter out the item with id 10 when tradeDataTypeDropdown is 'BD'
												.filter((product: any) => tradeDataTypeDropdown !== 'BD' || product.id !== 10)
												.map((product: any) => {
													const isSelected = selectedExcludedProducts.includes(product.id);
													return (
														<MenuItem
															key={product.id}
															value={product.id.toString()}
															style={{ color: productColors[product.id.toString()] || '#fff', fontWeight: 'bold' }}
															disabled={
																!isSelected &&
																selectedExcludedProducts.length >= (tradeDataTypeDropdown === 'BD' ? 9 : 10)
															}
														>
															{product.name}
														</MenuItem>
													);
												})
											: Object.entries(regionCodeToName).map(([code, name]) => {
												const isSelected = selectedExcludedRegions.includes(code);
												return (
													<MenuItem
														key={code}
														value={code}
														style={{ color: regionColors[code] || '#fff', fontWeight: 'bold' }}
														disabled={!isSelected && selectedExcludedRegions.length >= 4}
													>
														{name}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</div>
						)}
					</div>

					<div style={{ fontWeight: 'bold', fontSize: '16px', marginRight: '5px' }}>
						Total {tradeData[tradeDataDropdown]}: <span style={{ color: 'rgb(84, 77, 160)' }}>{totalValue}</span>
					</div>
				</div>
			)}

			<div style={{ position: 'relative', width: '100%', height: '100%' }}>
				{chartType === 'TM' && (
					<TradeTreemap
						year={year}
						country={country}
						targetCountry={targetCountry}
						tradeDataDropdown={tradeDataDropdown}
						tradeDataTypeDropdown={tradeDataTypeDropdown}
						dataClass={dataClass}
						hsbProductSelectionData={hsbProductSelectionData}
						hsProductData={hsProductData}
						sitcProductData={sitcProductData}
						levelWorldData={levelWorldData}
						loading={loading}
						setLoading={setLoading}
						setTotalValue={setTotalValue}
						product={product}
						productData={productData}
						levelProductDataWorld={levelProductDataWorld}
						excludeProductsCheckbox={excludeProductsCheckbox}
						selectedExcludedProducts={selectedExcludedProducts}
						selectedExcludedRegions={selectedExcludedRegions}
						allProductDataForWorld={allProductDataForWorld}
					/>
				)}

				<div style={{ position: 'relative', width: '100%', height: '100%', margin: 'auto' }}>
					{chartType === 'LG' && (
						<TradeLineGraph
							productData={productData}
							tradeDataDropdown={tradeDataDropdown}
							dataClass={dataClass}
							product={product}
							countryCount={countryCount}
						/>
					)}
				</div>

				{/* {showYearLimitMessage && (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							backgroundColor: 'rgba(255, 255, 255, 0.8)',
							padding: '10px 20px',
							borderRadius: '8px',
							boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
						}}
					>
						<h4 style={{ color: 'red', textAlign: 'center' }}>Animation available only till 2021</h4>
					</div>
				)} */}

				{loading && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(255, 255, 255, 0.8)',
						}}
					>
						{fetchingAllYears ? (
							<>
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
									<LinearProgress
										variant="determinate"
										value={progress}
										style={{ width: '80%', height: '10px', backgroundColor: 'rgb(169, 169, 169)', borderRadius: '5px' }}
										color="primary"
									/>
									<Typography
										style={{
											marginTop: '10px',
											color: 'rgb(84, 77, 160)',
											fontWeight: 'bold', // Make the text bold
											textAlign: 'center', // Center-align the text
											width: '80%', // Match the width of the progress bar
										}}
									>
										Loading Bilateral Dataset for animation - Please be patient...
										<br />
										<span
											style={{
												color: 'red',
												fontWeight: 'bold', // Make the red text also bold
											}}
										>
											The animation covers data up to 2021. For data beyond 2021, please use the slider or select a
											different year from the dropdown.
										</span>
									</Typography>
								</div>
							</>
						) : (
							<>
								<CircularProgress />
								<span style={{ marginLeft: '15px' }}>{loadingMsg}</span>
							</>
						)}
					</div>
				)}
			</div>

			<Grid
				container
				style={{
					width: chartType === 'TM' ? '95%' : '',
					marginTop: chartType === 'TM' ? '25px' : '0px',
					alignItems: 'center',
					justifyContent: chartType === 'TM' ? 'center' : 'flex-end',
				}}
			>
				{chartType === 'TM' && (
					<>
						<Slider
							style={{ color: year >= projectionYearStartDate ? 'red' : colourTitle, width: '100%' }} // Conditional color for Slider
							valueLabelDisplay="on"
							step={1}
							onChange={(event, newValue) => setYear(newValue as number)}
							marks={getSliderMarks()}
							min={1994}
							value={year}
							max={2050}
							classes={{ markLabel: classes.mark, marked: classes.marked }}
						/>
					</>
				)}
				<div
					style={{
						display: chartType === 'TM' ? 'flex' : '',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						marginTop: chartType === 'TM' ? '10px' : '0px',
					}}
				>
					{chartType === 'TM' && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center', marginLeft: '-20px' }}>
								{year !== 2050 ? (
									directionRef.current === 'play' && isAnimationClicked ? (
										<IconButton style={{ color: colourTitle }} onClick={handleAnimationControlStop}>
											<StopIcon />
										</IconButton>
									) : (
										<IconButton
											style={{ color: tradeDataTypeDropdown === 'BD' ? 'grey' : colourTitle }}
											disabled={tradeDataTypeDropdown === 'BD'} // Disable replay for BD
											onClick={handleAnimationControlPlay}
										>
											<PlayCircleFilled />
										</IconButton>
									)
								) : (
									<IconButton
										style={{ color: colourTitle }}
										onClick={() => {
											handleAnimationControlReplay();
										}}
										name="replay"
									>
										<ReplayOutlined />
									</IconButton>
								)}
								<InputLabel style={{ color: colourTitle, fontWeight: 'bold', fontSize: '13px', marginTop: '9px' }}>
									<span style={{ color: colourTitle }}>Drag to Year </span>
									{!(tradeDataTypeDropdown === 'BD') && 'or press Play/Rewind to animate Map '}
									{tradeDataTypeDropdown === 'BD' && (
										<Tooltip style={{ color: colourTitle }} title={"Animation is not available for 'Bilateral Data'."}>
											<InfoOutlined fontSize="small" />
										</Tooltip>
									)}
								</InputLabel>
							</div>
						</div>
					)}
					<div
						style={{
							display: 'flex',
							justifyContent: chartType === 'TM' ? '' : 'flex-end',
							alignItems: 'center',
							marginRight: chartType === 'TM' ? '0px' : '20px',
							marginTop: chartType === 'TM' ? '6px' : '0px',
						}}
					>
						<InputLabel
							style={{
								color: colourTitle,
								fontWeight: 'bold',
								fontSize: '12px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							Data & Sources
							<InfoIcon fontSize="small" style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={openModal} />
						</InputLabel>
					</div>

					<MacroTradeProdsSourcesModal isOpen={open} handleClose={handleClose} />
				</div>
			</Grid>
		</div>
	);
};

export default MacroTradeProds;
