// NB THis is a temp "old" version (pre Auth0 being added) until we can get AuthO working (only works on old Netlify version of App)
// See more advanced file versions: InfoStart_inclAuth0.tsx  and  MCQs_inclAuth0.tsx
import React from 'react';
import { useEffect, useState, } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, makeStyles, Typography, useMediaQuery, } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/Layout';
import InfoStartCards from './InfoStartCards/InfoCards';
import { GetUserProfileInfo } from '../pocketbase/constants';
import LoadingScreen from '../components/LoadingScreen';
import AccordionForInfoPage from './InfoStartCards/InfoAccordions';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ScenarioArrowsGreen from '../images/ScenarioArrowsGradient_Green.jpg';
import IcebergBlue from '../images/IcebergBlue_Original.jpg';
import WindTurbineSkyOrange from '../images/Baltic_Wind_SkyOrange_318_159_01a.jpg';

import PersonIcon from '@material-ui/icons/Person';
import InfoStartLatest from './InfoStartCards/InfoStartLatest';
import { emissionsData, energyData, financeData, macroData, otherData } from '../utils/infoPageContent';
import theme from '../config/theme';
import { useHistory } from 'react-router-dom';
import InfoCarouselImages from './InfoStartCards/InfoCarouselImages';
import InfoNews from './InfoStartCards/InfoNews';
import InfoAdvertisement from './InfoStartCards/InfoAdvertisement';
import InfoInformationAndLatest from './InfoStartCards/InfoInformationAndLatest';

// EmissionsPieScreenshot.jpg

// MT - Where to position this without causing errors !	- had to use inline styles for Card below
//	const styles = useStyles();

// MT attempt to add a Modal for Demo .MP4 videos for each subject stream


// export default class Tab8 extends Component<unknown, State> {
const InfoPage = () => {
	let state = {
		selectOptions: [],
		id: '',
		titleAuthors: '',
		authors: '',
		title: '',
		modalOpen: false,
		edition: undefined,
	};

	const [userDetails, setUserDetails] = useState<any>({});
	const [loading, setLoading] = useState<any>(false);
	const smUpScreen = useMediaQuery('(min-width:960px)');
	const history = useHistory();
	//	const navigate = useNavigate(); // Correctly get the navigate function

	useEffect(() => {
		let profileID: any = localStorage.getItem('profileID');
		setLoading(true);
		GetUserProfileInfo(profileID)
			.then((res) => {
				setUserDetails(res);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [localStorage.getItem('profileID')]);

	return (
		<>
			{/* // div width should react to width of displayed data - How ?	 */}
			{loading ? (
				<LoadingScreen />
			) : (
				<Layout>
					<Box display={smUpScreen ? 'flex' : 'block'} justifyContent={'space-between'} marginBottom={1}>
						<Typography style={{ color: '#544DA0', fontSize: smUpScreen ? '26px' : '20px', fontWeight: 'bold' }}>
							{' '}
							risk2NZ-Quantify Economic Risks to Net Zero - Energy, Emissions & Investments
						</Typography>

						{localStorage.getItem('token') ? (
							<Box
								display={'flex'}
								alignItems="center"
								style={{
									border: '1px solid #cccccc',
									borderRadius: '12px',
									paddingRight: '8px',
									marginBottom: smUpScreen ? 0 : '8px',
								}}
							>
								<PersonIcon
									fontSize="small"
									style={{
										color: '#544DA0',
									}}
								/>
								<Typography
									style={{
										color: 'rgb(0,160,160)',
										fontSize: '14px',
										fontWeight: 600,
										marginLeft: '4px',
										textTransform: 'capitalize',
									}}
								>
									{`${userDetails?.first_name}: ${userDetails?.user_type}`}
									<span style={{
										color: userDetails.subscriptionStatus === 'Subscribed' ? 'rgba(46,139,87,1)' :
											userDetails.subscriptionStatus === 'Unsubscribed' ? 'rgba(255, 0, 0, 1)' :
												userDetails.subscriptionStatus === 'Trial' ? 'rgba(221,110,16,1)' : '#00a0a0'
									}}>
										{` [${userDetails.subscriptionStatus}]`}
									</span>
								</Typography>
							</Box>
						) : (
							<Box
								display={'flex'}
								alignItems="center"
								style={{
									marginBottom: smUpScreen ? 0 : '8px',
								}}
							>
								<Button
									variant="contained"
									color="primary"
									style={{
										textTransform: 'unset',
										fontWeight: 'bold',
										fontSize: '14px',
										marginLeft: '12px',
										padding: '2px 8px',
									}}
									onClick={() => {
										history.push('/signin');
									}}
								>
									Login
								</Button>
							</Box>
						)}
					</Box>

					{/* <div style={{}}>
					<span style={{ display: 'inline-block', width: '30%' }}>
						{' '}
						<Select options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />{' '}
					</span>
					<span style={{ color: '#544DA0', fontSize: '15px', fontWeight: 'bold' }}>{'  '}Textbook:</span>{' '}
					<span
						style={{ display: 'inline-block', paddingLeft: '10px', width: '60%', backgroundColor: '#f2f0e6', color: '#c14f00', fontWeight: 'bold', fontSize: '17px', textAlign: 'left', border: '0px solid', }}  >
						<strong>
							{this.state.title}&nbsp;&nbsp;{this.state.authors}
						</strong>{' '}
						&nbsp;&nbsp;<strong>{this.state.edition}</strong>
					</span>
				</div> */}

					<Grid container spacing={2}>
						{/* <Grid item xs={12} sm={6} md={4}>
							<InfoStartCards
								title={`Welcome: ${userDetails?.first_name}  ${userDetails?.last_name} [${userDetails?.organisation}]`}
								objectFit="fill"
								subTitle={`Access Level: ${userDetails?.user_type}-${userDetails?.stream} `}
								body={`
											<ul>
											<li>you have access to the following Tabs: <b>[Macro]</b></li>
											<li>your course/CPD textbook is: <b>[Sloman]</b>.</li>
											<li>if you would like access to restricted Tabs, please tick this box(es) below & we will forward this expression of interest
											to your Organisation:</li> 
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Energy: <b>x</b>&nbsp;&nbsp;&nbsp;
											&nbsp;&nbsp;&nbsp;Climate: <b>x</b>&nbsp;&nbsp;&nbsp;
											&nbsp;&nbsp;&nbsp;Finance: <b>x</b>
											<ul>`}
							/>
						</Grid> */}
						<Grid item xs={12} md={7}>
							<InfoStartCards
								title="<strong>Why?</strong> - riskNZ fills knowledge gaps in academia/SMEs re: Economics/Net Zero risks"
								imgSrc={[IcebergBlue]}
								objectFit="fill"
								subTitle="The Global Economy is inherently uncertain, particularly with De-Globalisation & rapid Energy transformation.
								<strong>riskNZ</strong> provides applied data & risk awareness/quantification skills to help users fathom the different, possible trajectories & scenarios toward 2050 & Net Zero carbon - see <a href='/FAQ'  style='text-decoration:underline'> <strong>FAQ</strong> </a> for details."
								body={`
								<ul>
								<li><strong>Students - </strong></i> riskNZ fills crucial gaps in academic curricula in Economics/Business & Finance;</li>
								<li><strong>SMEs/Firms</strong> - are you aware of the Emissions situation of the countries,firms/sectors & banks in your supply chain ?</li>
								<ul>`}
							/>
							{/* //  MT Image change to riskNZ screenshots */}
							<InfoStartCards
								// imgSrc={[SunburstPurpleIcon, SunburstPurpleIcon, SunburstPurpleIcon]}
								imgSrc={[ScenarioArrowsGreen]}
								title={`<strong>What?</strong>- "Stress-Test" & Visualise the Global Economic pathways to Net Zero, 2050`}
								objectFit="fill"
								subTitle="Energy Use, CO2 emmissions & Portfolio ESG ratings are largely economy-driven but climate modelling often 
								assumes 'straight-line' Global economy trajectories. <b>riskNZ</b> brings real-world economic models/scenario analysis to the decarbonisation challenge via
								equiping user&apos;s risk quantification of key uncertainties:"
								body={`<ul>
									<li>de-facto economic trajectory to 2050 will likely see significant (global) economic/tech "shocks" en-route...</li>
									<li>..users can explore typical macro scenarios & their transmissions using topical, pre-configured, macro/tech scenarios.</li>
									<li>riskNZ is the"front-end" to MEF's suite of Global Economic-Energy-Emissions models used in
									"stress-testing" consultancy...</li>
									<li>..it augments training textbooks/courses by outlining <i>globally-consistent</i> impacts of Macro/Tech shocks - &ldquo;Stress Testing&rdquo;</li>
									<li>animated maps/charts display shock-impacts across a range of domains: Economic, Energy, Climate/Emissions
									and Financial/ESG</li>
									<li>	all (animated) response maps, charts & tables are downloadable for inclusion in user&rdquo;s own reports (eg
									risk or senstitivity analysis)</li>
									<ul>`}
							/>
							<InfoStartCards
								title="<strong>How?</strong> - Guide to use this App for different Subjects/Users"
								imgSrc={[WindTurbineSkyOrange]}
								objectFit="fill"
								subTitle="riskNZ covers 4 distinct, but related, &ldquo;subject streams&ldquo; that are impacted by Macroeconomic &ldquo;shocks&ldquo;: <b>Macro</b>,
								<b style='color:rgba(221,110,16,1)'>Energy</b>,
								<b style='color:rgba(46,139,87,1)' >Emissions</b> &
								<b style='color:rgba(0,115,237,1)' >Finance/ESG.</b>
								Users can subcriber to a single subject stream OR explore transmissions across all 4 for more comprehensive
								risk picture and quantification. Scenario visualisations are a key output"
								body={`
								<ul>
								<li>&ldquo;pre-cooked&rdquo; or &ldquo;ready-reckoner&ldquo; macro/tech scenarios display for student users, grouped by
								shock <i><strong>Type</strong></i> & severity... </li>
								<li> ..business users instead see real-world, contemporary scenarios; student users will see "ready-reckoner" learning scenarios </li>
								<li> impacts viewable across the Macroeconomy, Energy Use/Supply, GHG emissions by Nation/Sector/Firm, Portfolio Returns/ESG-ratings </li>
								<li> scenario impacts are presented in both levels and a <strong>&ldquo;change from base&rdquo;</strong> format </li>
								<li> MCQ Tab integrates with course/CPD textbook chapter to display Multiple Choice Questions relating to scenarios selected ..</li>
								<li>..on MCQ completion, users/student answers are then auto-scored for real-time, self-paced learning with learning progress tracked</li>
								<ul>`}
							/>
							{/* <hr /> */}
							{localStorage.getItem('token') ? (
								// Render AccordionForInfoPage if there are access rights
								<AccordionForInfoPage
									title="Subject:"
									//									font-Size ='10px'
									categoryDescription="DESCRIPTION OF COVERAGE:"
									header={true}
									color="#544DA0"
								/>
							) : (
								// Render the <h5> element if there are no access rights
								<h5
									style={{
										color: '#544DA0',
										fontSize: '14px',
										fontWeight: 600,
										paddingLeft: '4px',
										textTransform: 'uppercase',
									}}
								>
									Subject Stream/Area -{' '}
									<Box
										component={'span'}
										style={{
											color: 'red',
										}}
									>
										No access rights
									</Box>{' '}
									- Description of:
								</h5>
							)}
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('macro') || userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title={
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<span>Macro</span>
										<span style={{
											color: 'red',
											paddingLeft: '1px',
											fontSize: '12px',
											fontWeight: 'normal',
											textTransform: 'none'
										}}>Demo</span>

										{/* <button  title="View Demo video of How to use Macro Tabs"
											onClick={() => {
												history.push('/MacroMap');
												setTimeout(() => {
													window.open(
														'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4', '_blank', 
														'width=600,height=400,scrollbars=no,resizable=yes' // Small window dimensions
													);		}, 500); // Delay to ensure navigation is completed
											}}
											style={{ backgroundColor: 'rgba(84,77,160,.7)',
												marginLeft: 'auto', color: '#ffffff', fontWeight: 'bold', border: 'yes', borderRadius: '4px',
												padding: '5px 10px ', cursor: 'pointer',fontSize: '0.9em', marginRight: 10 }}
										>Demo</button> */}
									</div>
								}
								body={macroData}
								color="rgba(84,77,160,1)" background="rgba(84,77,160,0.20)"
								categoryDescription="macroeconomies - Global/National scenario impacts"
								history={history}
							/>
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('energy') || userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title={
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<span>Energy</span>
										<span style={{
											color: 'red',
											paddingLeft: '1px',
											fontSize: '12px',
											fontWeight: 'normal',
											textTransform: 'none'
										}}>Demo</span>
										{/* <button title="View Demo video of How to use Energy Tabs"
											onClick={() => alert('Go to EnergyStream_Demo.MP4')} // Replace with your actual functionality
											style={{
												backgroundColor: 'rgba(221,110,16,.7)',
												marginLeft: 'auto', color: '#ffffff', fontWeight: 'bold', border: 'yes', borderRadius: '4px',
												padding: '5px 10px ', cursor: 'pointer', fontSize: '0.9em', marginRight: 10
											}}
										>Demo</button> */}
									</div>
								}
								body={energyData}
								color="rgba(221,110,16,1)"
								background="rgba(221,110,16,0.2)"
								categoryDescription="Energy Use/Capacity - Global/National scenario impacts"
								history={history}
							/>
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('climate') ||
										userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title={
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<span>Emissions</span>
										<span style={{
											color: 'red',
											paddingLeft: '1px',
											fontSize: '12px',
											fontWeight: 'normal',
											textTransform: 'none'
										}}>Demo</span>
										{/* <button title="View Demo video of How to use GHG/Emissions Tabs"
											onClick={() => alert('Go to GHGemissions_Demo.MP4')} // Replace with your actual functionality
											style={{
												backgroundColor: 'rgba(46,139,87,.7)',
												marginLeft: 'auto', color: '#ffffff', fontWeight: 'bold', border: 'yes', borderRadius: '4px',
												padding: '5px 10px ', cursor: 'pointer', fontSize: '0.9em', marginRight: 10
											}}
										>Demo</button> */}
									</div>
								}

								body={emissionsData}
								color="rgba(46,139,87,1)"
								background="rgba(46,139,87,0.2)"
								categoryDescription="GHG emissions - Global/National scenario impacts"
								history={history}
							/>
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('finance') ||
										userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title={
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<span>Finance</span>
										<span style={{
											color: 'red',
											paddingLeft: '1px',
											fontSize: '12px',
											fontWeight: 'normal',
											textTransform: 'none'
										}}>Demo</span>
										{/* <button title="View Demo video of How to use Finance/ESG Tabs"
											onClick={() => alert('Go to FinanceESG_Demo.MP4')} // Replace with your actual functionality
											style={{
												backgroundColor: 'rgba(0,115,237,.7)',
												marginLeft: 'auto', color: '#ffffff', fontWeight: 'bold', border: 'yes', borderRadius: '4px',
												padding: '5px 10px ', cursor: 'pointer', fontSize: '0.9em', marginRight: 10
											}}
										>Demo</button> */}
									</div>
								}
								body={financeData}
								color="rgba(0,115,237,1)"
								background="rgba(0,115,237,0.2)"
								categoryDescription="Portfolio Returns & ESG ratings; - scenario impacts"
								history={history}
							/>
							<AccordionForInfoPage
								accessIcon={
									localStorage.getItem('token') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title="General"
								body={otherData}
								color="rgba(84,77,160,1)"
								background="rgba(211, 211, 211, 0.3)"
								categoryDescription="Glossary, FAQs & Textbook or Applied MCQs (scenario-specific)"
							/>
						</Grid>
						{smUpScreen ? (
							<>
								<Grid item xs={12} md={3}>
									<InfoCarouselImages />
									<InfoNews smUpScreen={smUpScreen} />
								</Grid>
								<Grid item xs={12} md={2}>

									<InfoAdvertisement />
									<InfoInformationAndLatest />
								</Grid>
							</>
						) : (
							<>
								<Grid item xs={12}>
									<Grid item style={{ display: 'flex' }} xs={12}>
										<Grid item xs={6} style={{ marginRight: '10px' }}>
											<InfoCarouselImages />
											<Grid>

											</Grid>
										</Grid>
										<Grid item xs={6}>
											<InfoAdvertisement />
											<InfoInformationAndLatest />
										</Grid>
									</Grid>
									<Grid item>
										<InfoNews smUpScreen={smUpScreen} />
									</Grid>
								</Grid>
							</>
						)}
					</Grid>
				</Layout>
			)}
		</>
	);
};

export default InfoPage;
