import React from 'react';
import { Grid, Select, MenuItem, FormHelperText, FormControl } from '@material-ui/core';
import { ImpactVariables, lineGraphVariables } from '../../utils/constants';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

interface Props {
	variables: ImpactVariables[];
	colour: string;
	colourTitle: string;
	handleOnChangeClimateVariable: any;
	handleOnChangeLineGraphVariable: any;
	variable: string[];
	lineGraphVariable: string[];
	allowMultipleSelection?: boolean;
}

const MapPageVariableSelection: React.FC<Props> = ({
	variables,
	colour,
	colourTitle,
	handleOnChangeClimateVariable,
	handleOnChangeLineGraphVariable,
	variable,
	lineGraphVariable,
	allowMultipleSelection = false
}) => {
	const onChangeClimateVariable = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		let value: any = e.target.value;
		// If multiple selection is not allowed, wrap the single value in an array
		if (!allowMultipleSelection) {
			value = [value].filter(Boolean);
		}
		handleOnChangeClimateVariable(value);
	};

	const onChangeLineGraphVariable = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		handleOnChangeLineGraphVariable(e.target.value);
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={6} md={10}>
				<FormControl fullWidth>
					<Select
						fullWidth
						multiple={allowMultipleSelection}
						value={allowMultipleSelection ? variable : (variable[0] || '')}
						onChange={onChangeClimateVariable}
						style={{ float: 'right', background: colour, color: colourTitle, fontWeight: 'bold' }}
					>
						{variables.map(({ value, label, subscripStatus, backgroundColor }, index) => {
							const isBlank = value === 'blank';
							const isDisabled =
								isBlank ||
								(localStorage.getItem('subscription') == 'Unsubscribed' && subscripStatus != 'Unsubscribed');

							return (
								<MenuItem
									key={index}
									value={value}
									disabled={isDisabled}
									style={{
										background: backgroundColor ? backgroundColor : 'light-grey',
										color: colourTitle,
										fontWeight: backgroundColor ? 'bold' : 'normal',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										...(isBlank && {
											opacity: 1,
											pointerEvents: 'none'
										})
									}}
								>
									{label}
									{localStorage.getItem('subscription') == 'Unsubscribed' &&
										subscripStatus != 'Unsubscribed' &&
										value !== 'blank' ? (
										<LockOutlinedIcon
											fontSize="small"
											style={{
												fontSize: '16px',
											}}
										/>
									) : null}
								</MenuItem>
							);
						})}
					</Select>
					<FormHelperText style={{ color: colourTitle }}>
						Select variable(s) above to map-display and/or animate Global impacts for selected Scenario(s){' '}
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={6} md={2}>
				<FormControl fullWidth>
					<Select
						fullWidth
						multiple
						value={lineGraphVariable}
						onChange={onChangeLineGraphVariable}
						renderValue={(selected: any) => {
							if (selected.length === 0) {
								return <em>Select...</em>;
							}
							let tempArr: any = [];
							selected?.map((selectValue: any) => {
								lineGraphVariables?.map((lineTempVar: any) => {
									if (lineTempVar?.value == selectValue) {
										tempArr?.push(lineTempVar?.label);
									}
								});
							});
							return tempArr.join(',');
						}}
						style={{ float: 'right', background: colour, color: colourTitle, fontWeight: 'bold' }}
					>
						{lineGraphVariables.map((lineVar: any, index: number) => (
							<MenuItem
								key={index}
								value={lineVar?.value}
								style={{
									background: 'light-grey',
									color: colourTitle,
									fontWeight: 'normal',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
								disabled={
									localStorage.getItem('subscription') == 'Unsubscribed' && lineVar?.subscripStatus != 'Unsubscribed'
										? true
										: false
								}
							>
								{lineVar?.label}{' '}
								{localStorage.getItem('subscription') == 'Unsubscribed' &&
									lineVar?.subscripStatus != 'Unsubscribed' &&
									lineVar?.value != 'blank' && (
										<LockOutlinedIcon
											fontSize="small"
											style={{
												fontSize: '16px',
											}}
										/>
									)}
							</MenuItem>
						))}
					</Select>
					<FormHelperText style={{ color: colourTitle }}>Global/Regional</FormHelperText>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default MapPageVariableSelection;
