import React, { ChangeEvent, useEffect, useState } from 'react';
import Highcharts, { SeriesClickEventObject } from 'highcharts';
import StopIcon from '@material-ui/icons/Stop';
import MacroMapSourcesModal from '../modals/MacroMapSourcesModal';
import EnergyMapSourcesModal from '../modals/EnergyMapSourcesModal';
import GHGMapSourcesModal from '../modals/GHGMapSourcesModal';
import PortMapSourcesModal from '../modals/PortMapSourcesModal';
import { InputLabel, Grid, Slider, IconButton } from '@material-ui/core';
import {
	HighchartsMapChart,
	HighmapsProvider,
	MapSeries,
	Loading,
	Subtitle,
	Title,
	Tooltip,
	ColorAxis,
} from 'react-jsx-highmaps';
import mapTemp from '@highcharts/map-collection/custom/world-lowres.geo.json';
import { makeStyles } from '@material-ui/core';
import { countryMappings } from '../../utils/constants';
import RegisterYourInterestPromptModal from '../modals/RegisterYourInterest';
import ErrorBoundary from '../ErrorBoundary';
import { Info, PlayCircleFilled, ReplayOutlined } from '@material-ui/icons';
import scenarios from '../../utils/sfScenarios';
import BusinessScenarioSizes from '../../utils/bfScenarios';
import { formatList } from '../../utils/utilities';

type CSVData = Record<string, number> & { Date: number };
type LineChartData = {
	name: string;
	data: number[];
	color?: string;
	dashStyle?: any;
	visible?: boolean;
	type?: string;
};
interface Props {
	colourTitle: string;
	currentData: CSVData[];
	scenarioSize: string[];
	variable: string[];
	handleLineDataCallback: any;
	handleScenSizeCallback: any;
	lineData: LineChartData[];
	dataPrefix: string;
	loading: boolean;
	colourDark: string | undefined;
	maxColour: string;
	title: string;
	shockImpactStartYr: number;
	mapData: any;
	mapDataHover: any;
	scenarioType: string;
	directionRef: any;
	resetValues: boolean;
	handleDirectionRefCallback: any;
	handleAnimationCallback: any;
	handleSelectedYearCallback: any;
	handleAnimationClickCallback: any;
	handleCallbackSelectedCountries: any;
	variables: any;
	type: any;
	format: string;
	mixedSelectedPercentVariable?: boolean;
}
const MapPageWorldMap: React.FC<Props> = ({
	colourTitle,
	currentData,
	scenarioSize,
	variable,
	handleLineDataCallback,
	handleScenSizeCallback,
	lineData,
	dataPrefix,
	loading,
	colourDark,
	maxColour,
	title,
	shockImpactStartYr,
	mapData,
	mapDataHover,
	scenarioType,
	directionRef,
	resetValues,
	handleDirectionRefCallback,
	handleAnimationCallback,
	handleSelectedYearCallback,
	handleAnimationClickCallback,
	handleCallbackSelectedCountries,
	variables,
	type,
	format,
	mixedSelectedPercentVariable,
}) => {
	const [geoMapChart, setGeoMapChart] = useState<any>();
	const [openRegisterInterestPopup, setOpenRegisterInterestPopup] = useState<boolean>(false);
	const [selectedCountriesForGraph, setSelectedCountriesForGraph] = useState<CSVData[]>([]);
	const [selectedYear, setSelectedYear] = useState<number>(shockImpactStartYr);

	// Data & Souces Info Modal at bottom of each MapPage variant
	const [openEnergyMapSources, setOpenEnergyMapSources] = useState<boolean>(false);
	const [openSourcesMacroMap, setopenSourcesMacroMap] = useState<boolean>(false);
	const [openGHGMapSources, setOpenGHGMapSources] = useState<boolean>(false);
	const [openPortMapSources, setOpenPortMapSources] = useState<boolean>(false);
	const [isAnimationClicked, setIsAnimationClicked] = React.useState(false);

	const states: Highcharts.SeriesStatesOptionsObject = {
		select: {
			color: '#a4edba',
			borderColor: 'black',
		},
	};

	const useStyles = makeStyles((theme) => ({
		mark: { color: colourTitle, fontWeight: 'bold' },
		marked: { marginBottom: '0px', marginLeft: '12px' },
	}));

	const validateData = (data: any) => {
		return data.map((point: any) => {
			if (isNaN(point)) {
				console.error('Invalid data point:', point);
				return 0;
			}
			return point;
		});
	};

	const onClick = (e: SeriesClickEventObject) => {
		const { 'hc-key': countryKey, 'country-abbrev': countryName }: any = e.point.properties;
		const { 'cnty-suffix': countrySuffix, 'subscrip-status': subscripStatus }: any = countryMappings.find(
			({ 'hc-key': hcKey }) => hcKey === countryKey
		)!;

		if (localStorage.getItem('subscription') == 'Unsubscribed' && subscripStatus != 'Unsubscribed') {
			e.preventDefault();
			setOpenRegisterInterestPopup(true);
			return;
		}

		//		const worldData = currentData.map((value) => value[Object.keys(value).find((key) => key.startsWith('WD'))!]);
		let tempDataArr: any = [];

		currentData?.map((dataVar: any) => {
			const worldData = validateData(
				dataVar?.data.map(
					//				(value: any) => value[Object.keys(value).find((key) => key.startsWith('WD') || key.endsWith('_WD'))!]
					(value: any) => value[Object.keys(value).find((key) => key.startsWith('WD') || key.endsWith('WD'))!]
				)
			);
			const countryData: any = validateData(
				dataVar?.data.map((value: any) => value[Object.keys(value).find((key) => key.endsWith(`_${countrySuffix}`))!])
			);
			let varStubTemp: any = variables.find(({ value }: any) => value === dataVar?.varName)?.varStub ?? '';

			if (variable?.length > 1) {
				let tempSelectedCountries: any = [];
				tempSelectedCountries?.push({ country: countryName, suffix: countrySuffix, data: countryData });
				setSelectedCountriesForGraph(tempSelectedCountries);
				handleCallbackSelectedCountries(tempSelectedCountries);
			} else {
				let tempSelectedCountries: any = [...selectedCountriesForGraph];
				let flag = false;
				tempSelectedCountries?.map((selection: any, index: number) => {
					if (selection?.suffix == countrySuffix) {
						tempSelectedCountries.splice(index, 1);
						flag = true;
					}
				});
				if (!flag) {
					tempSelectedCountries?.push({ country: countryName, suffix: countrySuffix, data: countryData });
				}
				setSelectedCountriesForGraph(tempSelectedCountries);
				handleCallbackSelectedCountries(tempSelectedCountries);
			}
			if (scenarioSize?.length > 1) {
				let tempArr = [...scenarioSize];
				tempArr.slice(tempArr.length - 1);
				handleScenSizeCallback(tempArr);
				return;
			}

			if (variable?.length > 1) {
				tempDataArr.push({ name: `${varStubTemp}-${countryName}`, data: countryData });
			} else {
				if (lineData.map(({ name }) => name).includes(`${varStubTemp}-${countryName}`)) {
					handleLineDataCallback((prevState: any) => {
						const newState = prevState.filter((item: any) => {
							return item.name !== `${varStubTemp}-${countryName}`;
						});
						return newState;
					});
				} else {
					if (lineData.length < 2) {
						handleLineDataCallback([
							{ name: `${varStubTemp}-World`, data: worldData },
							{ name: `${varStubTemp}-${countryName}`, data: countryData },
						]);
					} else {
						handleLineDataCallback((prevState: any) => [
							...prevState,
							{ name: `${varStubTemp}-${countryName}`, data: countryData },
						]);
					}
				}
			}
		});
		if (variable?.length > 1) {
			handleLineDataCallback(tempDataArr);
		}
	};

	// code from fiddle to change the behaviour of the click property
	(function (H) {
		// if (variable?.length == 1) {
		H.Point.prototype.select = function (selected, accumulate) {
			var point = this,
				series = point.series,
				chart = series.chart,
				pick = H.pick;
			selected = pick(selected, !point.selected);
			// @ts-ignore
			this.selectedStaging = selected;
			// fire the event with the default handler
			// @ts-ignore
			point.firePointEvent(
				selected ? 'select' : 'unselect',
				{
					accumulate: accumulate,
				},
				function () {
					// @ts-ignore
					point.selected = point.options.selected = selected;
					// @ts-ignore
					series.options.data[series.data.indexOf(point)] = point.options;
					// @ts-ignore
					point.setState(selected && 'select');
				}
			);
		};
		// }
	})(Highcharts);

	const afterChartCreated = (chart: any) => {
		setGeoMapChart(chart);
	};

	const openSourcesMacroMapModal = (): void => {
		if (dataPrefix == 'MacroMap') {
			setopenSourcesMacroMap(true);

			return;
		}
		if (dataPrefix == 'GHGMap') {
			setOpenGHGMapSources(true);

			return;
		}
		if (dataPrefix == 'EnergyMap') {
			setOpenEnergyMapSources(true);

			return;
		}
		setOpenPortMapSources(true);
	};

	const handleSourcesModalClose = (): void => {
		if (dataPrefix == 'MacroMap') {
			setopenSourcesMacroMap(false);

			return;
		}
		if (dataPrefix == 'GHGMap') {
			setOpenGHGMapSources(false);

			return;
		}
		if (dataPrefix == 'EnergyMap') {
			setOpenEnergyMapSources(false);

			return;
		}
		setOpenPortMapSources(false);
	};

	const handleAnimationControlPlay = () => {
		setIsAnimationClicked(true);
		handleDirectionRefCallback('play');
		handleAnimationClickCallback(true);
		return;
	};

	const handleAnimationControlStop = (event: any) => {
		if (selectedYear > shockImpactStartYr && directionRef == 'play' && isAnimationClicked) {
			handleDirectionRefCallback('pause');
			return;
		}
	};

	useEffect(() => {
		if (selectedYear >= 2050) {
			setIsAnimationClicked(false);
			handleAnimationClickCallback(false);
			return;
		} else if (directionRef == 'play') {
			const timer = setTimeout(() => {
				// Only update if we're still playing
				if (directionRef === 'play') {
					setSelectedYear(selectedYear + 1);
					handleSelectedYearCallback(selectedYear + 1);
					if (selectedYear + 1 == 2049) {
						handleSelectedYearCallback(2050);
					}
				}
			}, 350);
			return () => clearTimeout(timer);
		} else {
			setIsAnimationClicked(false);
			handleAnimationClickCallback(false);
			return;
		}
	}, [selectedYear, directionRef]);

	useEffect(() => {
		setSelectedYear(shockImpactStartYr);
		handleSelectedYearCallback(shockImpactStartYr);
	}, [scenarioType, shockImpactStartYr]);

	const handleSelectedYear = (event: ChangeEvent<{}>, newValue: number | number[]) => {
		setSelectedYear(newValue as number);
		handleSelectedYearCallback(newValue as number);
		handleAnimationCallback();
	};

	const tooltipOptions: Highcharts.SeriesTooltipOptionsObject = {
		pointFormatter: function () {
			let finalMouseOverValue: any;
			for (var i = 0; i < mapData.length; i++) {
				let info: any = mapData[i];
				if (info[0] == this.properties['hc-key']) {
					finalMouseOverValue = info[1]; // on mouse over shows the original value instead of absolute value
					break;
				}
			}
			return `${this.name}: ${finalMouseOverValue}`;
		},
	};

	const mapNavigationOptions: Highcharts.MapNavigationOptions = {
		enabled: true,
		enableMouseWheelZoom: true,
		buttonOptions: { verticalAlign: 'bottom' },
	};

	useEffect(() => {
		if (isAnimationClicked) {
			handleAnimationCallback();
		}
		// setMapData(newMapDataHover)
		//  to set the US selected --> not working
		// const index = geoMapChart?.series[0].points.findIndex((item: any) => {
		// 	return item['hc-key'] === 'us';
		// });
		// if (index > -1 && geoMapChart && geoMapChart.series[0] && geoMapChart.series[0].points[index])
		// 	geoMapChart.series[0].points[index].setState(true && 'select');
	}, [selectedYear]);

	useEffect(() => {
		if (selectedYear >= 2050) {
			setIsAnimationClicked(false);
			handleAnimationClickCallback(false);
			return;
		} else if (directionRef == 'play') {
			const timer = setTimeout(() => {
				setSelectedYear(selectedYear + 1);
				handleSelectedYearCallback(selectedYear + 1);
				handleAnimationCallback();
				if (selectedYear + 1 == 2049) {
					handleSelectedYearCallback(2050);
				}
			}, 350); //control animation speed
			return () => clearTimeout(timer);
		} else {
			setIsAnimationClicked(false);
			handleAnimationClickCallback(false);
			return;
		}
	}, [selectedYear, directionRef]);

	useEffect(() => {
		if (resetValues) {
			if (directionRef === 'play') {
				handleDirectionRefCallback('pause');
				setIsAnimationClicked(false);
				handleAnimationClickCallback(false);
			}
			setSelectedYear(shockImpactStartYr);
			handleSelectedYearCallback(shockImpactStartYr);
		}
	}, [resetValues, shockImpactStartYr]);

	const marks = [
		{ value: shockImpactStartYr, label: shockImpactStartYr },
		{ value: 2050, label: '2050' },
	];
	const classes = useStyles();

	return (
		<ErrorBoundary>
			<HighmapsProvider Highcharts={Highcharts}>
				<HighchartsMapChart
					mapNavigation={mapNavigationOptions}
					map={mapTemp}
					legend={{ enabled: true }}
					callback={afterChartCreated}
					containerProps={{ style: { height: '65vh' } }}
				>
					<Loading isLoading={loading}>Data loading...</Loading>
					<Title useHTML={true}>{title}</Title>
					<Subtitle style={{ color: '#786c3a' }}>
						{type === 'student'
							? scenarios[scenarioType]?.sizes.find((item: any) => item.id === 'cen')
								? 'Central Forecast(cen)'
								: `Scenario: ${scenarioSize[0]} - ${(() => {
									let text = scenarios[scenarioType].sizes.find(({ id }) => scenarioSize[0]?.includes(id))?.text ?? '';
									text = text.substring(2, text.length) + ` - ${formatList[format.toUpperCase()]}`;
									return text;
								})()}`
							: BusinessScenarioSizes[scenarioType]?.sizes.find((item: any) => item.id === 'cen')
								? 'Central Forecast(cen)'
								: `Scenario: ${scenarioSize[0]} - ${(() => {
									let text =
										BusinessScenarioSizes[scenarioType].sizes.find(({ id }) => scenarioSize[0]?.includes(id))?.text ?? '';
									text = text.substring(2, text.length) + ` - ${formatList[format.toUpperCase()]}`;
									return text;
								})()}`}
					</Subtitle>
					<MapSeries
						name={title}
						states={states}
						allowPointSelect={true}
						onClick={onClick}
						data={mapDataHover}
						tooltip={tooltipOptions}
						selected={true}
						allAreas={dataPrefix == 'EnergyMap' ? false : true}
					/>
					<Tooltip />
					<ColorAxis maxColor={maxColour} type="linear" />
					{/* <ColorAxis maxColor={maxColour} type="logarithmic" allowNegativeLog="true"   /> */}
				</HighchartsMapChart>
			</HighmapsProvider>
			{/* <TextField name="HiddenFieldCtrlKey"  /> */}
			<Grid>
				<Slider
					style={{ color: colourTitle, width: '95%' }}
					valueLabelDisplay="on" // "auto"
					step={1}
					defaultValue={shockImpactStartYr}
					onChange={handleSelectedYear}
					marks={marks}
					min={shockImpactStartYr}
					value={selectedYear}
					max={2050}
					classes={{ markLabel: classes.mark, marked: classes.marked }}
				></Slider>

				<InputLabel
					style={{
						color: colourTitle,
						fontWeight: 'bold',
						fontSize: '13px',
						marginLeft: '-10px',
						marginTop: '12px',
					}}
				>
					{/* https://codesandbox.io/s/66983676control-the-material-ui-slider-with-a-play-and-stop-buttons-in-react-js-m9349?file=/demo.tsx   For Slider animation */}
					{selectedYear != 2050 ? (
						directionRef === 'play' && isAnimationClicked ? (
							<IconButton
								style={{ color: colourTitle }}
								onClick={handleAnimationControlStop}
								name="stop"
							>
								<StopIcon />
							</IconButton>
						) : (
							<IconButton
								style={{
									color: mixedSelectedPercentVariable ? 'rgba(0, 0, 0, 0.38)' : colourTitle
								}}
								onClick={handleAnimationControlPlay}
								name="play"
								disabled={mixedSelectedPercentVariable}
							>
								<PlayCircleFilled />
							</IconButton>
						)
					) : selectedYear == 2050 && !isAnimationClicked ? (
						<IconButton
							style={{ color: colourTitle }}
							onClick={() => {
								setSelectedYear(shockImpactStartYr);
								handleSelectedYearCallback(shockImpactStartYr);
							}}
							name="replay"
						>
							<ReplayOutlined />
						</IconButton>
					) : (
						<IconButton
							style={{ color: colourTitle }}
							onClick={handleAnimationControlStop}
							name="stop"
						>
							<StopIcon />
						</IconButton>
					)}
					{/* <IconButton style={{ color: colourTitle }} onClick={handleAnimationControl}><StopIcon /> </IconButton> */}
					Drag to Year or press Play/Rewind to animate Map{' '}
				</InputLabel>
			</Grid>
			<MacroMapSourcesModal isOpen={openSourcesMacroMap} handleClose={handleSourcesModalClose} />
			<EnergyMapSourcesModal isOpen={openEnergyMapSources} handleClose={handleSourcesModalClose} />
			<GHGMapSourcesModal isOpen={openGHGMapSources} handleClose={handleSourcesModalClose} />
			<PortMapSourcesModal isOpen={openPortMapSources} handleClose={handleSourcesModalClose} />
			<RegisterYourInterestPromptModal
				isOpen={openRegisterInterestPopup}
				handleClose={() => {
					setOpenRegisterInterestPopup(false);
				}}
				heading="Uh Oh! You're trying to access a premium feature."
			/>
			<InputLabel style={{ float: 'right', color: colourDark, fontWeight: 'bold', fontSize: '12px' }}>
				Data & Sources
				<Info
					data-testid="info-button"
					fontSize="small"
					style={{ cursor: 'pointer' }}
					onClick={() => openSourcesMacroMapModal()}
				/>
			</InputLabel>
		</ErrorBoundary>
	);
};

export default MapPageWorldMap;
