type SubscriptionStatus = 'Subscribed' | 'Unsubscribed';
// type SubscriptionStatus = 'Unsubscribed' | 'Subscribed';

interface CountrySubscriptionData {
  countryStatus: SubscriptionStatus;
  partnerStatus: SubscriptionStatus;
}

// Unsubscribed = available for registered users
// Subcribed = ONLY visible (unlocked) for Subscribers 
export const countrySubscriptionData: Record<string, CountrySubscriptionData> = {
  ARG: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  AUS: { countryStatus: 'Unsubscribed', partnerStatus: 'Unsubscribed' },
  AUT: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  BEL: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  BGR: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  BRA: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  CAN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  CHE: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  CHL: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  CHN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  CZE: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  DEU: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  ESP: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  EST: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  FIN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  FRA: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  GRC: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  HKG: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  HUN: { countryStatus: 'Unsubscribed', partnerStatus: 'Unsubscribed' },
  IDN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  IND: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  IRL: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  ISL: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  ISR: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  ITA: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  JPN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  KOR: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  LTU: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  LUX: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  LVA: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  MAC: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  MEX: { countryStatus: 'Unsubscribed', partnerStatus: 'Unsubscribed' },
  MYS: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  NLD: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  NOR: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  NZL: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  PHL: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  POL: { countryStatus: 'Unsubscribed', partnerStatus: 'Unsubscribed' },
  PRT: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  ROU: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  RUS: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  SGP: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  SVK: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  SVN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  SWE: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  THA: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  TUR: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  GBR: { countryStatus: 'Unsubscribed', partnerStatus: 'Unsubscribed' },
  USA: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  VNM: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  ZAF: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
  TWN: { countryStatus: 'Subscribed', partnerStatus: 'Subscribed' },
};

export const yearSubscriptionData: Record<number, string> = {
  1994: 'Unsubscribed',
  1995: 'Unsubscribed',
  1996: 'Unsubscribed',
  1997: 'Unsubscribed',
  1998: 'Unsubscribed',
  1999: 'Unsubscribed',
  2000: 'Unsubscribed',
  2001: 'Unsubscribed',
  2002: 'Unsubscribed',
  2003: 'Unsubscribed',
  2004: 'Unsubscribed',
  2005: 'Unsubscribed',
  2006: 'Unsubscribed',
  2007: 'Unsubscribed',
  2008: 'Unsubscribed',
  2009: 'Unsubscribed',
  2010: 'Unsubscribed',
  2011: 'Unsubscribed',
  2012: 'Unsubscribed',
  2013: 'Unsubscribed',
  2014: 'Unsubscribed',
  2015: 'Unsubscribed',
  2016: 'Unsubscribed',
  2017: 'Unsubscribed',
  2018: 'Unsubscribed',
  2019: 'Unsubscribed',
  2020: 'Unsubscribed',
  2021: 'Subscribed',
  2022: 'Unsubscribed',
  2023: 'Unsubscribed',
  2024: 'Unsubscribed',
  2025: 'Unsubscribed',
  2026: 'Unsubscribed',
  2027: 'Unsubscribed',
  2028: 'Unsubscribed',
  2029: 'Unsubscribed',
  2030: 'Unsubscribed',
  2031: 'Unsubscribed',
  2032: 'Unsubscribed',
  2033: 'Unsubscribed',
  2034: 'Unsubscribed',
  2035: 'Unsubscribed',
  2036: 'Unsubscribed',
  2037: 'Unsubscribed',
  2038: 'Unsubscribed',
  2039: 'Unsubscribed',
  2040: 'Unsubscribed',
  2041: 'Unsubscribed',
  2042: 'Unsubscribed',
  2043: 'Unsubscribed',
  2044: 'Unsubscribed',
  2045: 'Unsubscribed',
  2046: 'Unsubscribed',
  2047: 'Unsubscribed',
  2048: 'Unsubscribed',
  2049: 'Unsubscribed',
  2050: 'Unsubscribed',
}

export const dataImportExportSubscriptionData: Record<string, string> = {
  exports: 'Subscribed',
  imports: 'Unsubscribed'
}

export const coverageSubscriptionData: Record<string, string> = {
  WD: 'Subscribed',
  BD: 'Unsubscribed'
}

export const productListSubscriptionData: Record<string, string> = {
  0: 'Unsubscribed',
  1: 'Unsubscribed',
  2: 'Unsubscribed',
  3: 'Unsubscribed',
  4: 'Unsubscribed',
  5: 'Unsubscribed',
  6: 'Unsubscribed',
  7: 'Unsubscribed',
  8: 'Unsubscribed',
  9: 'Unsubscribed',
  10: 'Unsubscribed',
  11: 'Subscribed',
  12: 'Subscribed',
}

export const lineGraphCountryCountSubscriptionData: Record<string, string> = {
  5: 'Subscribed',
  10: 'Unsubscribed',
  15: 'Unsubscribed',
  20: 'Unsubscribed',
  All: 'Unsubscribed',
}
