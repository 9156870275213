import { lazy } from 'react';
import SignIn from '../components/auth/Signin';
import SignUp from '../components/auth/Signup';
import GHGmap from '../Tabs/GHGmap';
import TradeFlowIcon from '../images/TradeFlowArrowPurpleDark.jpg';
import TradeFlowOrangeIcon from '../images/TradeFlowOrange.jpg';
import MontecarloIcon from '../images/MontecarloFanDarkGreen.jpg';
import IndustryIconGreen from '../images/IndustryDarkGreen200.jpg';
// import MacroTradeProdsIcon from '../images/MacroTradeProds.jpg'
import MacroTradeProdsIcon from '../images/Treemap_mauve.png'
import FirmIconGreen from '../images/FirmDarkGreen200.jpg';
import MontecarloFanLightBlue from '../images/MontecarloFanLightBlue.jpg';
import ESGdiyGauge from '../images/ESGdiyBlue_Original.jpg';
import PORTdiyGauge from '../images/PORTdiyBlue_Original.jpg';
import ESGportfolioIcon from '../images/ESGportfolioBlue_Original.jpg';
import mGEMnetworkIcon from '../images/mGEM_NetworkPurple_Original.jpg';
import EnergyPieLineIcon from '../images/EnergyPieLineOrange.jpg';
import EnergyFlowIcon from '../images/EnergyFlow.jpg';
// import EnergyBubbleIcon from '../images/Bubbles_Orange.jpg';
import EnergyBubbleIcon from '../images/Bubbles_Orange.jpg';

// import SunburstGreenIcon from '../images/sunburst-green.jpg';
import SunburstGreenIcon from '../images/Sunburst_Icon_Green.jpg';
import SunburstPurpleIcon from '../images/sunburst-purple.jpg';
import SunburstBlueIcon from '../images/Sunburst_Icon_BlueESG.jpg';
import SunburstOrangeIcon from '../images/sunburst-orange.jpg';
import ForgotPassword from '../components/auth/ForgotPassword';
import ResetPassword from '../components/auth/ResetPassword';
import EmailVerification from '../components/auth/EmailVerification';
import VerifyEmailBanner from '../components/auth/verifyEmail';
import FAQ from '../Tabs/FAQ';
import SignInForIndividuals from '../components/auth/SigninIndividual';
import SignUpForIndividuals from '../components/auth/SignupIndividual';
import EnergyTradeLinks from '../Tabs/EnergyTradeLinks';
import MacroTradeProds from '../Tabs/MacroTradeProds';

//permissions (CustomerType Array) moved to pocketbase

const Info = lazy(() => import('../Tabs/InfoStart'));
const MacroMap = lazy(() => import('../Tabs/MacroMap'));
const PortMap = lazy(() => import('../Tabs/PortMap'));
const GHGMap = lazy(() => import('../Tabs/GHGmap'));
const Emissions = lazy(() => import('../Tabs/GHGstocks'));
// const EmissionsBreakdown = lazy(() => import('../Tabs/Industry/IndustryPie'));
const IndustryPie = lazy(() => import('../Tabs/GHGsect/IndustryPie'));

const EnergySum = lazy(() => import('../Tabs/EnergySum/EnergyPie'));
const EnergySunburst = lazy(() => import('../Tabs/EnergySunburst'));
const EnergyMap = lazy(() => import('../Tabs/EnergyMap'));
const EnergyFlow = lazy(() => import('../Tabs/EnergyFlow'));
const EnergyBubble = lazy(() => import('../Tabs/EnergyBubble'));

const GHGbreakdownFirms = lazy(() => import('../Tabs/GHGfirms/GHGbreakdownFirms'));
const WorldTrade = lazy(() => import('../Tabs/MacroLinksTrade'));
const EnergyLinksTrade1 = lazy(() => import('../Tabs/EnergyTradeLinks'));
// const LinksMacro = lazy(() => import('../Tabs/MacroLinks'));
const ModelLinksMacro = lazy(() => import('../Tabs/MacroModelLinks'));
const Portfolio = lazy(() => import('../Tabs/PortMontecarlo'));
const Montecarlo = lazy(() => import('../Tabs/GHGsectMC'));
const SunburstGHG = lazy(() => import('../Tabs/GHGSunburst'));
const SunburstMacro = lazy(() => import('../Tabs/MacroSunburst'));
const PortSunburst = lazy(() => import('../Tabs/PortSunburst'));
const PortEsgDIY = lazy(() => import('../Tabs/PortEsgDIY'));
const PortEsg = lazy(() => import('../Tabs/PortEsg'));
const PortRtrnDIY = lazy(() => import('../Tabs/PortRtrnDIY'));
const NewMacroTab = lazy(() => import('../Tabs/NewMacroTab'));

const MCQ = lazy(() => import('../Tabs/MCQ'));
const Glossary = lazy(() => import('../Tabs/Glossary'));


export const restrictedRoutes = [
	{
		path: '/MacroMap',
		restricted: false,
		component: MacroMap,
		title: 'Macro',
		tooltipTitle: 'MacroMap-see Global Macro shock-impacts-animated Maps/Charts',
	},
	{
		path: '/MacroSunburst',
		restricted: false,
		component: SunburstMacro,
		title: 'SB',
		imgSrc: SunburstPurpleIcon,
		tooltipTitle: 'Macro Sunburst-drilldown into key Macro variables across Countries/Zones to 2050',
	},
	{
		path: '/MacroTradeLinks',
		restricted: false,
		component: WorldTrade,
		title: 'Trade',
		imgSrc: TradeFlowIcon,
		tooltipTitle: 'Macro Trade-see trade Links between major economies',
	},
	{
		path: '/MacroTradeProds',
		restricted: false,
		component: MacroTradeProds,
		title: 'Macro Trade Prods',
		imgSrc: MacroTradeProdsIcon,
		tooltipTitle: 'Product Trade-see Treemap of product mix of Exports & Imports',
	},
	{
		path: '/MacroMGEM',
		restricted: false,
		// component: LinksMacro,
		component: ModelLinksMacro,
		title: 'mGEM',
		imgSrc: mGEMnetworkIcon,
		tooltipTitle: 'mGEM-see mGEM Model Macroeconomic model Linkages',
	},
	// {
	// 	path: '/NewMacroTab',
	// 	restricted: false,
	// 	component: NewMacroTab,
	// 	title: 'NewTab',
	// 	imgSrc: SunburstPurpleIcon,
	// 	tooltipTitle: 'Dummy Template New Macro Tab',
	// },
	{
		path: '/EnergyMap',
		restricted: false,
		component: EnergyMap,
		title: 'EnergyMap',
		tooltipTitle: 'EnergyMap-see Global energy shock-impacts-animated Maps/Charts',
	},
	{
		path: '/EnergySunburst',
		restricted: false,
		component: EnergySunburst,
		title: 'EnergySunburst',
		imgSrc: SunburstOrangeIcon,
		tooltipTitle: 'Energy Sunburst-drilldown into Energy Demand/Generation Across & Between Countries/Zones to 2050',
	},
	{
		path: '/EnergyTradeLinks',
		restricted: false,
		component: EnergyLinksTrade1,
		title: 'EnergyLinksTrade ',
		imgSrc: TradeFlowOrangeIcon,
		tooltipTitle: 'Energy Trade- see energy trade Links between major economies',
	},
	{
		path: '/EnergyFlow',
		restricted: false,
		component: EnergyFlow,
		title: 'Energy Flow',
		imgSrc: EnergyFlowIcon,
		tooltipTitle: 'Energy Flow',
	},
	{
		path: '/EnergyBubble',
		restricted: false,
		component: EnergyBubble,
		title: 'Energy Bubble',
		imgSrc: EnergyBubbleIcon,
		tooltipTitle: 'Energy Bubble',
	},
	{
		path: '/EnergySum',
		restricted: false,
		component: EnergySum,
		title: 'Energy',
		imgSrc: EnergyPieLineIcon,
		tooltipTitle: 'Pie-see Energy Demand/Generation by Country/Sector to 2050',
	},

	{
		path: '/GHGMap',
		restricted: false,
		component: GHGmap,
		title: 'GHG',
		tooltipTitle: 'GHGMap-see GHG Emissions Impacts of Macro shocks-via animated Maps/Charts',
	},
	{
		path: '/GHGSunburst',
		restricted: false,
		component: SunburstGHG,
		imgSrc: SunburstGreenIcon,

		title: 'SunburstGHG',
		tooltipTitle: 'Climate-Sunburst drilldown into GHG emission shares by Country-Sector-Firm',
	},

	{
		path: '/GHGIndustry',
		restricted: false,
		component: IndustryPie,
		imgSrc: IndustryIconGreen,
		title: 'Industry',
		tooltipTitle: 'Industry-see GHG emissions by Country/Industry-Sector to 2050',
	},

	{
		path: '/GHGFirms',
		restricted: false,
		component: GHGbreakdownFirms,
		title: 'Firms',
		imgSrc: FirmIconGreen,
		tooltipTitle: 'Firms/TPI-see GHG emissions by Corporates/Brand to 2050',
	},
	{
		path: '/GHGMontecarlo',
		restricted: false,
		component: Montecarlo,
		title: 'MonteCarlo',
		imgSrc: MontecarloIcon,
		tooltipTitle: 'MonteCarlo -see forward-looking risk fans for GHG Emissions pathways to 2050',
	},
	{
		path: '/PortMap',
		restricted: false,
		component: PortMap,
		title: 'PortMap',
		imgSrc: ESGdiyGauge,
		tooltipTitle: 'Finance Map-see finance Impacts of Macro shocks-via animated Maps/Charts',
	},
	{
		path: '/PortSunburst',
		restricted: false,
	  component: PortSunburst,
		title: 'SB',
		imgSrc: SunburstBlueIcon,
		tooltipTitle: 'Port Sunburst-drilldown into key data on Bank Lending to the Fossil Fuel Energy Sector + NZBA members',
	},
	{
		path: '/PortEsgDIY',
		restricted: false,
		component: PortEsgDIY,
		title: 'ESG-RatingDIY',
		imgSrc: ESGdiyGauge,
		tooltipTitle: 'ESG-RatingDIY-Calculate dynamic Esg rating for your investment portfolio',
	},
	{
		path: '/PortEsg',
		restricted: false,
		component: PortEsg,
		title: 'PortEsg',
		imgSrc: ESGportfolioIcon,
		tooltipTitle: 'ESG-Portfolio -see dynamic Esg rating for Popular investment portfolios',
	},
	{
		path: '/PortRtrnDIY',
		restricted: false,
		component: PortRtrnDIY,
		title: 'PortRtrnDIY',
		imgSrc: PORTdiyGauge,
		tooltipTitle: 'Return-RatingDIY-Calculate dynamic return rating for your investment portfolio',
	},

	{
		path: '/PortMontecarlo',
		restricted: false,
		component: Portfolio,
		title: 'Portfolio',
		imgSrc: MontecarloFanLightBlue,
		tooltipTitle: 'Portfolio -explore scenario risks to Popular investment portfolio returns ',
	},
	{
		path: '/MCQ',
		restricted: false,
		component: MCQ,
		title: 'MCQ',
		tooltipTitle: 'Other-View MCQ tests related to the course textbook & specific to selected shocks',
	},
	{
		path: '/Glossary',
		restricted: false,
		component: Glossary,
		title: 'Glossary',
		tooltipTitle: 'Other-View interactive Glossary of Economic/ESG/Energy Terms',
	},
	{
		path: '/FAQ',
		restricted: false,
		component: FAQ,
		title: 'FAQ',
		tooltipTitle: 'Frequently asked questions',
	},
	//ignore these routes for header
	{
		path: '/emissions',
		restricted: false,
		component: Emissions,
		title: 'Macro-Map',
		tooltipTitle: 'View Global Macroeconomic Impacts of Shocks',
	},

	// {
	// 	path: '/signup',
	// 	restricted: false,
	// 	component: SignUp,
	// },
	{
		path: '/signup',
		restricted: false,
		component: SignUpForIndividuals,
	},
	{
		path: '/forgot-password',
		restricted: false,
		component: ForgotPassword,
	},
	{
		path: '/confirm-password-reset/:token',
		restricted: false,
		component: ResetPassword,
	},
	{
		path: '/confirm-email-verification/:token',
		restricted: false,
		component: EmailVerification,
	},
	{
		path: '/verify-email/:email',
		restricted: false,
		component: VerifyEmailBanner,
	},
	// {
	// 	path: '/signin',
	// 	restricted: false,
	// 	component: SignIn,
	// },
	{
		path: '/signin',
		restricted: false,
		component: SignInForIndividuals,
	},
	{
		path: '/',
		restricted: false,
		component: Info,
	},
];

//moved to pocketbase
// export const CustomerType = [
// 	{
// 		roleType: 'student',
// 		userArea: [
// 			{
// 				name: 'macro',
// 				//see above array for all the routes
// 				permittedTabs: ['/MacroMap', '/MacroTradeLinks', '/MacroMGEM', '/MacroSunburst', '/MCQ', '/Glossary'], // includes any routes of tabs students can access
// 			},
// 			{
// 				name: 'energy',
// 				permittedTabs: ['/EnergySum', '/EnergySunburst', '/EnergyMap', '/MCQ', '/Glossary'],
// 			},

// 			{
// 				name: 'emissions',
// 				permittedTabs: ['/GHGMap', '/GHGIndustry', '/GHGFirms', '/GHGMontecarlo', '/GHGSunburst', '/MCQ', '/Glossary'],
// 			},
// 			{
// 				name: 'finance',
// 				permittedTabs: ['/MacroMap', '/PortMontecarlo', '/PortEsg', '/PortRtrnDIY', '/PortEsgDIY', '/MCQ', '/Glossary'],
// 			},
// 			{
// 				name: 'Full',
// 				permittedTabs: [
// 					'/MacroMap',
// 					'/MacroTradeLinks',
// 					'/MacroMGEM',
// 					'/MacroSunburst',

// 					'/EnergySum',
// 					'/EnergySunburst',
// 					'/EnergyMap',

// 					'/GHGMap',
// 					'/GHGSunburst',
// 					'/GHGIndustry',
// 					'/GHGFirms',
// 					'/GHGMontecarlo',

// 					'/PortEsgDIY',
// 					'/PortMontecarlo',
// 					'/PortEsg',
// 					'/PortRtrnDIY',

// 					'/MCQ',
// 					'/Glossary',
// 				],
// 			},
// 		],
// 	},
// 	{
// 		roleType: 'business',
// 		userArea: [
// 			{
// 				name: 'Exporter-SME',
// 				permittedTabs: ['/MacroMap', '/MacroTradeLinks', '/MacroMGEM', '/MacroSunburst', '/Glossary'],
// 			},
// 			{
// 				name: 'Financial',
// 				permittedTabs: ['/MacroMap', '/PortMontecarlo', '/PortEsg', '/PortRtrnDIY', '/PortEsgDIY', '/Glossary'],
// 			},
// 			{
// 				name: 'Energy',
// 				permittedTabs: ['/EnergySum', '/EnergySunburst', '/EnergyMap', '/Glossary'],
// 			},
// 			{
// 				name: 'emissions',
// 				permittedTabs: ['/GHGMap', '/GHGIndustry', '/GHGSunburst', '/Glossary'],
// 			},
// 			{
// 				name: 'Full',
// 				permittedTabs: [
// 					'/MacroMap',
// 					'/MacroTradeLinks',
// 					'/MacroMGEM',
// 					'/MacroSunburst',
// 					//
// 					'/EnergySum',
// 					'/EnergySunburst',
// 					'/EnergyMap',
// 					//
// 					'/GHGMap',
// 					'/GHGSunburst',
// 					'/GHGIndustry',
// 					'/GHGFirms',
// 					'/GHGMontecarlo',
// 					//
// 					'/PortEsgDIY',
// 					'/PortMontecarlo',
// 					'/PortEsg',
// 					'/PortRtrnDIY',
// 					//
// 					'/Glossary',
// 				],
// 			},
// 		],
// 	},
// 	{
// 		roleType: 'educator',
// 		userArea: [
// 			{
// 				name: 'macro',
// 				//see above array for all the routes
// 				permittedTabs: ['/MacroMap', '/MacroTradeLinks', '/MacroMGEM', '/MacroSunburst', '/Glossary'], // includes any routes of tabs students can access
// 			},
// 			{
// 				name: 'energy',
// 				permittedTabs: ['/EnergySum', '/EnergySunburst', '/EnergyMap', '/MCQ', '/Glossary'],
// 			},

// 			{
// 				name: 'emissions',
// 				permittedTabs: ['/GHGMap', '/GHGIndustry', '/GHGFirms', '/GHGMontecarlo', '/GHGSunburst', '/MCQ', '/Glossary'],
// 			},
// 			{
// 				name: 'finance',
// 				permittedTabs: ['/MacroMap', '/PortMontecarlo', '/PortEsg', '/PortRtrnDIY', '/PortEsgDIY', '/MCQ', '/Glossary'],
// 			},
// 			{
// 				name: 'Full',
// 				permittedTabs: [
// 					'/MacroMap',
// 					'/MacroTradeLinks',
// 					'/MacroMGEM',
// 					'/MacroSunburst',
// 					//
// 					'/EnergySum',
// 					'/EnergySunburst',
// 					'/EnergyMap',
// 					//
// 					'/GHGMap',
// 					'/GHGSunburst',
// 					'/GHGIndustry',
// 					'/GHGFirms',
// 					'/GHGMontecarlo',
// 					//
// 					'/PortEsgDIY',
// 					'/PortMontecarlo',
// 					'/PortEsg',
// 					'/PortRtrnDIY',
// 					//
// 					'/MCQ',
// 					'/Glossary',
// 				],
// 			},
// 		],
// 	},
// ];
