import { ImpactVariables } from "./constants";

interface SectionAccumulator {
  currentSection: string;
  sections: { [key: string]: ImpactVariables[] };
}

export function generateColorGradient(baseColor: string, secondColor: string, variables: ImpactVariables[]): ImpactVariables[] {
  // Keep existing helper functions (hexToRgb, rgbToHex, rgbToHsl, hslToRgb)...
  // Helper function to convert hex to RGB
  const hexToRgb = (hex: string): number[] => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : [0, 0, 0];
  };
  let globalIndex = 0;

  // Helper function to convert RGB to hex
  const rgbToHex = (r: number, g: number, b: number): string => {
    return '#' + [r, g, b].map(x => {
      const hex = Math.round(x).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  };


  // Function to generate a variant of a color
  const generateVariant = (color: string, index: number): string => {
    const rgb = hexToRgb(color);
    const [h, s, l] = rgbToHsl(rgb[0], rgb[1], rgb[2]);

    // Reduce the hue adjustment to keep colors in similar families
    const hueAdjust = (index % 2 === 0) ? 2 * index : -2 * index; // Reduced from 5 to 2

    // More controlled saturation and lightness adjustments
    const saturationAdjust = (index % 2 === 0) ? 8 * (index + 1) : -8 * (index + 1); // Reduced from 15 to 8
    const lightnessAdjust = (index % 2 === 0) ? 5 * (index + 1) : -5 * (index + 1); // Reduced from 10 to 5

    // Tighter constraints on the ranges
    const newHue = ((h + hueAdjust + 360) % 360); // Ensure hue stays positive
    const newSaturation = Math.min(85, Math.max(35, s + saturationAdjust)); // Narrower range
    const newLightness = Math.min(75, Math.max(35, l + lightnessAdjust)); // Narrower range

    const newRgb = hslToRgb(newHue, newSaturation, newLightness);
    return rgbToHex(newRgb[0], newRgb[1], newRgb[2]);
  };

  // Convert RGB to HSL
  const rgbToHsl = (r: number, g: number, b: number): number[] => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0, s = 0, l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return [h * 360, s * 100, l * 100];
  };

  // Convert HSL to RGB
  const hslToRgb = (h: number, s: number, l: number): number[] => {
    h /= 360;
    s /= 100;
    l /= 100;

    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let r, g, b;
    if (s === 0) {
      r = g = b = l;
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  // Group variables by section (keep existing grouping logic)
  const initialAccumulator: SectionAccumulator = {
    currentSection: '',
    sections: {}
  };

  const isColorTooSimilar = (color1: string, color2: string): boolean => {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    const difference = Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) +
      Math.pow(rgb1[1] - rgb2[1], 2) +
      Math.pow(rgb1[2] - rgb2[2], 2)
    );

    return difference < 10; // Increased threshold for more distinct colors
  };

  const usedColors = new Set<string>();

  const grouped = variables.reduce((acc: SectionAccumulator, curr: ImpactVariables) => {
    const isHeader = curr.value === 'blank';

    if (isHeader) {
      acc.currentSection = curr.label;
      acc.sections[curr.label] = [];
    } else if (acc.currentSection) {
      acc.sections[acc.currentSection].push(curr);
    }

    return acc;
  }, initialAccumulator);

  // Generate alternating colors for each section
  const result: ImpactVariables[] = [];

  Object.entries(grouped.sections).forEach(([sectionName, sectionVariables]) => {
    // Add section header
    const header = variables.find(v => v.value === 'blank' && v.label === sectionName);
    if (header) result.push(header);

    // Generate colors for section items
    sectionVariables.forEach((variable, localIndex) => {
      if (variable.value !== 'blank') {
        let color: string;
        let attempts = 0;
        const maxAttempts = 10;

        do {
          if (globalIndex === 0) {
            // Only use baseColor for the very first item overall
            color = baseColor;
          } else if (globalIndex === 1) {
            // Only use secondColor for the second item overall
            color = secondColor;
          } else if (globalIndex % 2 === 0) {
            // Generate variants based on global position
            color = generateVariant(baseColor, Math.floor(globalIndex / 2) + attempts);
          } else {
            color = generateVariant(secondColor, Math.floor(globalIndex / 2) + attempts);
          }
          attempts++;
        } while (
          (usedColors.has(color) ||
            Array.from(usedColors).some(usedColor => isColorTooSimilar(color, usedColor))) &&
          attempts < maxAttempts &&
          globalIndex > 1
        );

        usedColors.add(color);
        result.push({
          ...variable,
          color: color
        });
        globalIndex++;
      }
    });
  });
  return result;
}
