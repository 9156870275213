import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles, Button } from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChatIcon from '@material-ui/icons/Chat';

interface CardProps {
	imgSrc?: any | [];
	title?: any;
	subTitle?: any;
	body?: any;
	objectFit?: string;
	borderColor?: string;
}

const InfoStartLatest: React.FC<CardProps> = ({ imgSrc, title, subTitle, body, objectFit, borderColor }) => {
	const [macroListings, setMacroListings] = useState([]);
	const [energyListings, setEnergyListings] = useState([]);
	const [GHGListings, setGHGListings] = useState([]);
	const [ESGListings, setESGListings] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [url, setUrl] = useState('');

	const corsUrl = 'https://api.rss2json.com/v1/api.json?rss_url=';
	const getFeedListing = (url: any) => axios.get(`${corsUrl}${url}`);

	const useStyles = makeStyles({
		accordionSummary: {
			minHeight: '28px !important',
			height: '28px !important',
			padding: 0,
			paddingLeft: 7,
			background: 'rgba(180, 180, 180,0.15)',
		},
		accordionTitle: {
			fontSize: '11px',
			fontWeight: 'bold',
			//			color: 'rgba(38,166,91,.8)',
			color: '#00a0a0',
			textTransform: 'uppercase',
		},
		listContainer: {
			display: 'block',
			padding: '4px',
			//			color: 'rgba(38,166,91,.8)',
			color: '#00a0a0',
		},
		mainList: {
			color: 'rgba(84,77,160,.7)',
			paddingLeft: '2px',
			fontWeight: 'bold',
			fontSize: '14px',
			cursor: 'pointer',
			listStyle: 'none',
			margin: 0,
			padding: 0
		},
		subList: {
			marginTop: '8px',
			paddingLeft: '20px',
		},
		listItem: {
			fontSize: '12px',
			marginBottom: '4px',
			cursor: 'pointer',
			listStyle: 'disc'
		},
		divider: {
			margin: '8px 0px 4px',
		},
		accordion: {
			border: '1px solid rgba(0, 0, 0, .2)',
			minHeight: '28px',
		},
		caseStudyButton: {
			marginLeft: '8px',
			padding: '2px 8px',
			minWidth: 'auto',
			fontSize: '11px',
			textTransform: 'none',
			backgroundColor: '#00a0a0',
			color: 'white',
			'& .MuiButton-startIcon': {
				marginRight: '2px',  // Reduce space between icon and text
				marginLeft: '-4px',  // Reduce left padding
			},
			'&:hover': {
				backgroundColor: '#008080',
			},
		},
		listItemContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
	});

	const downloadPDF = (e: React.MouseEvent, pdfUrl: string) => {
		e.stopPropagation();
		const xhr = new XMLHttpRequest();
		xhr.open('GET', `https://risknz.mef-uk.com/docs/${pdfUrl}`, true);
		xhr.responseType = 'blob';

		xhr.onload = function () {
			if (this.status === 200) {
				const blob = new Blob([this.response], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.style.display = 'none';
				link.href = url;
				link.setAttribute('download', pdfUrl);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}
		};

		xhr.send();
	};

	const classes = useStyles();

	const getMacroListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					i === 0 ? (item.source = 'bls') : (item.source = 'bea');
					items.push(item);
				});
			}
			setMacroListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getEnergyListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'eia';
					items.push(item);
				});
			}
			setEnergyListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getGHGListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'ghg';
					items.push(item);
				});
			}
			setGHGListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getESGListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'esg';
					items.push(item);
				});
			}
			setESGListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};


	// Energy RSS Feeds
	// 'https://rss-feed-api.aws.jyllands-posten.dk/energywatch.eu/latest.xml'
	// 'https://renewablesnow.com/news/news_feed/?topic=technology.xml'
	//

	// GHG EMissions RSS Feeds - to be initiated
	//   https://feeds.emissieautoriteit.nl/nieuws.rss


	React.useEffect(() => {
		if (!initialized) {
			getMacroListings(['https://apps.bea.gov/rss/rss.xml']);
			//.rss link not working -  Maybe needs renaming to xml?
			// getMacroListings(['https://www.bls.gov/feed/bls_latest.rss', 'https://apps.bea.gov/rss/rss.xml']);
			getEnergyListings([
				'https://www.eia.gov/rss/todayinenergy.xml',
				'https://services.rechargenews.com/api/feed/rss',])
			getGHGListings([
				'https://feeds.feedburner.com/ConservationInternationalBlog/ClimateChange.xml',]);
			getESGListings([
				'https://www.esgtoday.com/feed/',]);
			//				'https://www.eco-business.com/feeds/news.xml',  ]);
			setInitialized(true);
		}
	}, []);

	return (
		<Card
			style={{
				boxShadow: '3px 3px 10px rgba(84,77,160,0.3) !important',
				borderRadius: '6px',
				border: '1px solid rgba(84,77,160,0.2)',
			}}
		>
			<CardContent
				style={{
					padding: '4px',
				}}
			>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography
							title=""
							variant="body2"
							className={classes.accordionTitle}
						>
							WHO SHOULD USE APP ?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.listContainer}>
						<ul
							title=""
							className={classes.mainList}
						>
							Overview:
							<ul className={classes.subList}>
								{[
									{
										text: 'Exporters/SMEs',
										pdfUrl: 'riskNZ_Deploy_ExportersSME_01a.pdf',
										link: '/exporters'
									},
									{
										text: 'Consultancies',
										pdfUrl: 'riskNZ_Deploy_Consultancies_01a.pdf',
										link: '/consultancies'
									},
									{
										text: 'Investment/Fund Managers',
										pdfUrl: 'riskNZ_Deploy_InvestFundManagers_01a.pdf',
										link: '/investment-managers'
									},
									{
										text: 'Energy/Emissions Analysts',
										pdfUrl: 'riskNZ_Deploy_EnergyGHGAnalysts_01a.pdf',
										link: '/energy-analysts'
									},
									{
										text: 'Higher Education Institutions',
										pdfUrl: 'riskNZ_Deploy_UnivsTeachHE_01a.pdf',
										link: '/higher-education-institutions'
									},
									{
										text: 'Students/CPD',
										pdfUrl: 'riskNZ_Deploy_StudentsCPD_01a.pdf',
										//										link: '/students-CPD'
									}

								].map((item, index) => (
									<li key={index} className={classes.listItem}>
										<div className={classes.listItemContainer}>
											<span
												onClick={(e) => {
													e.stopPropagation();
													setTimeout(() => {
														window.open(`https://next.mef-uk.com/who${item.link}`, '_blank');
													}, 500);
												}}
											>
												{item.text}
											</span>
											<Button
												className={classes.caseStudyButton}
												startIcon={<GetAppIcon style={{ fontSize: 14 }} />}
												onClick={(e) => downloadPDF(e, item.pdfUrl)}
											>
												Guide
											</Button>
										</div>
									</li>
								))}
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>

				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}} */}
				{/* /> */}
				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title=""
							variant="body2"
							className={classes.accordionTitle}
						>
							MEF MODELS
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.listContainer}>
						<ul onClick={(e) => {
							e.stopPropagation();
							setTimeout(() => {
								window.open('https://next.mef-uk.com/who/', '_blank');
							}, 500);
						}}
							className={classes.mainList}
						> MEF Model Suite:
							<ul className={classes.subList}>
								<li title="Click to donwload .PDF guide" onClick={(e) => {
									setTimeout(() => {
										e.stopPropagation();
										window.open('https://next.mef-uk.com/who/mGEM-global-macro', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>mGEM Global Macro model</li>
								<li title="" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/finGEM-global-finance', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>finGEM Global Finance model</li>
								<li title="" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/eGEM-global-emissions', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>eGEM Global Emissions model</li>
								<li title="" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/enGEM-global-energy', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>enGEM Global Energy model</li>


								<li title="" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/enGEM-global-energy', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>DEFMOD Global Defence Spending model</li>
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>

				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}}
				/> */}
				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title=""
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							DATA/SCENARIO UPDATES
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						className={classes.listContainer}
					>
						<ul title="" className={classes.mainList} >
							Macro/Energy Data:
							<ul className={classes.subList}>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://data-explorer.oecd.org/', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>OECD</li>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											//											window.open('https://db.nomics.world/last-updates?tab=providers', '_blank');
											window.open('https://data.imf.org/?sk=388dfa60-1d26-4ade-b505-a05a558d9a42', '_blank');

										}, 500);
									}}
									className={classes.listItem}
								//								>DBNOMICS</li>
								>IMF</li>
							</ul>
							<ul title="" className={classes.mainList} >
								Electricity/Energy Data:
								<ul className={classes.subList}>
									<li
										onClick={(e) => {
											e.stopPropagation();
											setTimeout(() => {
												window.open('https://ember-energy.org/latest-updates/', '_blank');
											}, 500);
										}}
										className={classes.listItem}
									>Ember</li>
									<li
										onClick={(e) => {
											e.stopPropagation();
											setTimeout(() => {
												//											window.open('https://dashboard.neso.energy/genmix.html', '_blank');
												window.open('https://dashboard.neso.energy', '_blank');
											}, 500);
										}}
										className={classes.listItem}
									>NESO (UK Energy)</li>
								</ul>
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>

				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}}
				/> */}

				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title=""
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							PUBLICATIONS
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.mainList}>
						<ul title="" className={classes.mainList} >
							ESG/Finance:
							<ul className={classes.subList}>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://www.bankingonclimatechaos.org/?bank=JPMorgan%20Chase#fulldata-panel', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>Stranded Assets</li>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://bank.green/sustainable-eco-banks ', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>Bank Exposure App</li>
							</ul>
							<ul title="" className={classes.mainList} >
								Macroeconomics:
								<ul className={classes.subList}>
									<li
										onClick={(e) => {
											e.stopPropagation();
											setTimeout(() => {
												window.open('https://www.bankingonclimatechaos.org/?bank=JPMorgan%20Chase#fulldata-panel', '_blank');
											}, 500);
										}}
										className={classes.listItem}
									>Stranded Assets</li>
									<li
										onClick={(e) => {
											e.stopPropagation();
											setTimeout(() => {
												window.open('https://bank.green/sustainable-eco-banks ', '_blank');
											}, 500);
										}}
										className={classes.listItem}
									>Bank Exposure App</li>
								</ul>
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title=""
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							SUBSCRIBE
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.mainList}>
						<ul className={classes.subList}>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/subscribe/education-subscribe', '_blank');
									}, 500);
								}}
								className={classes.listItem}
							>Commercial/SME</li>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/subscribe/education-subscribe', '_blank');
									}, 500);
								}}
								className={classes.listItem}
							>Educational Institute</li>
						</ul>
					</AccordionDetails>
				</Accordion>


				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title=""
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							MEF Chatbot
							<ChatIcon style={{ color: 'rgba(38,166,91,1.0)', padding: '5px' }}
							/>
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.mainList}>
						<ul className={classes.subList}>
							<li title="Click to donwload .PDF guide" onClick={(e) => {
								setTimeout(() => {
									e.stopPropagation();
									window.open('https://next.mef-uk.com/who/mGEM-global-macro', '_blank');
								}, 500);
							}}
								className={classes.listItem}
							>Business Use
								<Button
									className={classes.caseStudyButton}
									startIcon={<GetAppIcon style={{ fontSize: 14 }} />}
									onClick={(e) => downloadPDF(e, '')}
								>
									Guide
								</Button>
							</li>
							<li>Student Use
								<Button
									className={classes.caseStudyButton}
									startIcon={<GetAppIcon style={{ fontSize: 14 }} />}
									onClick={(e) => downloadPDF(e, '')}
								>
									Guide
								</Button>
							</li>
						</ul>
					</AccordionDetails>
				</Accordion>


			</CardContent>
		</Card>
	);
};

export default InfoStartLatest;
