import SunburstGreenIcon from '../images/Sunburst_Icon_Green.jpg';
import SunburstPurpleIcon from '../images/sunburst-purple.jpg';
import SunburstOrangeIcon from '../images/sunburst-orange.jpg';
import SunburstBlueIcon from '../images/Sunburst_Icon_BlueESG.jpg';
import TradeFlowPurpleIcon from '../images/TradeFlowArrowPurpleDark.jpg';
import TradeFlowOrangeIcon from '../images/TradeFlowOrange.jpg';
import MacroTradeProdsIcon from '../images/Treemap_mauve.png'
import mGEMnetworkIcon from '../images/mGEM_NetworkPurple_Original.jpg';
import EnergyFlowIcon from '../images/EnergyFlow.jpg';
// import EnergyBubbleIcon from '../images/Bubbles_Orange.jpg';
import EnergyBubbleIcon from '../images/Bubbles_Orange.jpg';
import EnergyPieLineIcon from '../images/EnergyPieLineOrange.jpg';
import IndustryIconGreen from '../images/IndustryDarkGreen200.jpg';
import FirmIconGreen from '../images/FirmDarkGreen200.jpg';
import MontecarloIcon from '../images/MontecarloFanDarkGreen.jpg';
import ESGdiyGauge from '../images/ESGdiyBlue_Original.jpg';
import ESGportfolioIcon from '../images/ESGportfolioBlue_Original.jpg';
import PORTdiyGauge from '../images/PORTdiyBlue_Original.jpg';
import MontecarloFanLightBlue from '../images/MontecarloFanLightBlue.jpg';
import PublicIcon from '@material-ui/icons/Public';
import TimelineIcon from '@material-ui/icons/Timeline';
import { Box } from '@material-ui/core';
import React from 'react';

const createNameWithIcons = (name: string) => {
	return React.createElement('div', {
		style: { display: 'flex', alignItems: 'center' }
	}, [
		React.createElement(PublicIcon, { 
			key: 'public',
			style: { fontSize: 16 }
		}),
		React.createElement(TimelineIcon, {
			key: 'timeline', 
			style: { fontSize: 16 }
		}),
		React.createElement('span', { 
			key: 'text',
			style: { marginLeft: '8px' }
		}, name)
	])
 }

export const macroData = [
	{
		name: createNameWithIcons('MacroMap'),
		access: false,
		description: 'see how scenarios impact national & Global macroeconomies via impacts charts & heatmaps',
		imgSrc: '',
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/MacroMapAnim_01c.gif',
			route: '/MacroMap'
		}
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'look at Country composition of key Global Macro Variables by Country/Use-sector and to 2050',
		imgSrc: SunburstPurpleIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Sunburst_HowToUse.mp4',
			route: '/MacroSunburst'
		}
	},
	{
		name: 'Trade',
		access: false,
		description:
			'view underlying model trade linkages explaining global shock transmissions behind scenarios + global trade links',
		imgSrc: TradeFlowPurpleIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Sunburst_HowToUse.mp4',
			route: '/MacroTradeLinks'
		}
	},
	{
		name: 'TradeProds',
		access: false,
		description: 'Tree Map showing product mix of $bn Exports & Imports by Country-Year across SITC & HS trade classifications',
		//      imgSrc: '../../MacroTradeProds.jpg',
		imgSrc: MacroTradeProdsIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Sunburst_HowToUse.mp4',
			route: '/MacroTradeProds'
		}
	},
	{
		name: 'mGEM',
		access: false,
		description: 'View underlying MEF model linkages explaining shock transmissions behind scenarios + global trade links',
		imgSrc: mGEMnetworkIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Sunburst_HowToUse.mp4',
			route: '/MacroMGEM'
		}
	},
];

export const energyData = [
	{
		name: createNameWithIcons('EnergyMap'),
		access: false,
		description:
			'see how macroeconomic scenarios impact national & Global Energy variables via impacts charts & heatmaps',
		imgSrc: '',
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/EnergyMap'
		}
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'look at Country composition of Global Energy Demand by Country/Use-sector and to 2050',
		imgSrc: SunburstOrangeIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/EnergySunburst'
		}
	},
	{
		name: 'EnergyTrade',
		access: false,
		description: 'Observe trade partner countries/magnitudes for cross-border, pan-European Electricity flows',
		imgSrc: TradeFlowOrangeIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/EnergyTradeLinks'
		}
	},
	{
		name: 'EnergyFlow',
		access: false,
		description: 'Sankey chart displaying Energy Demand by Use-Sector and Energy/Electricity Supply mix to 2050',
		imgSrc: EnergyFlowIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/EnergyFlow'
		}
	},
	{
		name: 'EnergyBubble',
		access: false,
		description: 'Bubble chart displaying various historical/future relationships between Energy & Economy',
		imgSrc: EnergyBubbleIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/EnergyBubble'
		}
	},
	{
		name: 'Energy',
		access: false,
		description:
			'drilldown into the composition of Global Energy Demand by Country/Use-sector and to 2050 via Pie & Line charts',
		imgSrc: EnergyPieLineIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/EnergySum'
		}
	},
];
export const emissionsData = [
	{
		name: createNameWithIcons('GHGMap'),
		access: false,
		description: 'see how scenarios impact national & Global environmental outcomes via impacts charts & heatmaps',
		imgSrc: '',
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/GHGMap'
		}
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'gain overview of climate crisis challenge; view comparative GHG emissions by country and sector',
		imgSrc: SunburstGreenIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/GHGSunburst'
		}
	},
	{
		name: 'Industry',
		access: false,
		description: 'drilldown into the composition of overall GHG emissions by GHG gas and Country/Sector and over time',
		imgSrc: IndustryIconGreen,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/GHGIndustry'
		}
	},
	{
		name: 'Firms',
		access: false,
		description: 'drilldown into the composition of overall GHG emissions by GHG gas and Corporates and over time',
		imgSrc: FirmIconGreen,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/GHGFirms'
		}
	},
	{
		name: 'Montecarlo',
		access: false,
		description: 'explore risk around GHG emission trajectory to 2050',
		imgSrc: MontecarloIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/GHGMontecarlo'
		}
	},
];

export const financeData = [
	{
		name: createNameWithIcons('PortfolioMap'),
		access: false,
		description: 'see how scenarios impact national & Global investment returns via impacts charts & heatmaps',
		imgSrc: '',
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/PortMap'
		}
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'look at Country composition of key Finance/Lending Data for Fossil Fuel Firms by Banks',
		imgSrc: SunburstBlueIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Sunburst_HowToUse.mp4',
			route: '/PortSunburst'
		}
	},
	{
		name: 'ESG-RatingDIY',
		access: false,
		description: 'specify user portfolio asset allocation to generate live, dynamic Esg rating to 2050',
		imgSrc: ESGdiyGauge,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/PortEsgDIY'
		}
	},
	{
		name: 'ESG-Portfolio',
		access: false,
		description: 'see how scenarios impact financial portfolio ESG ratings with a Montecarlo risk fan overlay',
		imgSrc: ESGportfolioIcon,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/PortEsg'
		}
	},
	{
		name: 'Portfolio-DIY',
		access: false,
		description:
			'specify user portfolio asset allocation to generate live, dynamic Returns Forecasts/Scenarios to 2050',
		imgSrc: PORTdiyGauge,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/PortRtrnDIY'
		}
	},
	{
		name: 'Portfolio',
		access: false,
		description: 'see how scenarios impact financial portfolio returns with a Montecarlo risk fan overlay',
		imgSrc: MontecarloFanLightBlue,
		demoButton: {
			url: 'https://risknz.mef-uk.com/videos/Macro_Tab_HowToUse_01c.mp4',
			route: '/PortMontecarlo'
		}
	},
];
export const otherData = [
	{
		name: 'MCQ',
		access: false,
		description: 'relevant textbook MCQ & applied Task questions that relate to the chosen scenario',
		imgSrc: '',
	},
	{
		name: 'Glossary',
		access: false,
		description: 'an interactive Glossary of all economic, financial & environmental terms',
		imgSrc: '',
	},
	{
		name: 'FAQ',
		access: false,
		description: 'Frequently Asked Questions about riskNZ App',
		imgSrc: '',
	},
];
